<template>
  <div class="h-112">
    <div v-if="payflg">
        <p class=" text-xl text-center pt-40">该商品已经支付完成！</p>
    </div>
    <div v-else>
      <el-tabs v-model="activeName">
        <el-tab-pane label="线下支付" name="offline">
          <p class="font-bold text-lg truncate">{{ orderVo.goodsName }}</p>
          <p class="leading-6" v-if="type == 1">
            保证金:
            <span class="pl-4 text-lg text-primary">￥{{ orderVo.money }}</span>
          </p>
          <p class="leading-6" v-else>
            货款:
            <span class="pl-4 text-lg text-primary">￥{{ orderVo.money }}</span>
          </p>
          <el-card class="text-sm mt-2">
            <p class="leading-4">
              开户名称: <span class="pl-4">河北中瑞博雅科技有限公司</span>
            </p>
            <p class="leading-6">
              开户银行: <span class="pl-4">河北银行裕华区支行</span>
            </p>
            <p class="leading-4">
              汇款账号: <span class="pl-4">123 456 789 1233</span>
            </p>
          </el-card>
          <el-card class="mt-4">
            <div class="flex">
              <el-upload
                class="avatar-uploader"
                :action="url"
                :on-preview="handlePreview"
                :before-remove="beforeRemove"
                multiple
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
                :http-request="httpRequest"
              >
                <img
                  v-if="orderVo.payVoucher"
                  :src="orderVo.payVoucher"
                  class="avatar"
                />
                <i v-else class="avatar el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="ml-4 text-gray-500 pt-5">
                请上传支付凭证后，点击确认支付
              </div>
            </div>
            <el-button
              type="danger"
              class="w-full"
              :disabled="!orderVo.payVoucher"
              @click="pay"
              >确认支付</el-button
            >
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="支付宝" name="ali">
          <p class="font-bold text-lg truncate">{{ orderVo.goodsName }}</p>
          <p class="leading-6" v-if="type == 1">
            保证金:
            <span class="pl-4 text-lg text-primary">￥{{ orderVo.money }}</span>
          </p>
          <p class="leading-6" v-else>
            货款:
            <span class="pl-4 text-lg text-primary">￥{{ orderVo.money }}</span>
          </p>
          <div class="mt-4 w-full h-72">
            <div class="flex flex-col justify-between items-center border p-5">
              <img
                :src="orderVo.payQr"
                class="w-40 h-40 mb-5"
                v-show="orderVo.payQr"
              />
              <div
                class="flex justify-between items-center p-3 rounded-sm w-64"
              >
                <img src="@/assets/images/alipay/zhifubao.png" class="h-10" />
                <img src="@/assets/images/alipay/tuijian.png" class="h-4" />
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  props: ["goodsId", "type"],
  data() {
    return {
      payflg:false,
      url: "",
      websock: null,
      activeName: "ali",
      fileList: [],
      orderVo: {
        id: null,
        type: 1,
        goodsId: "8",
        goodsName: "",
        payVoucher: "",
        money: "",
        payType: "",
        outTradeNo: "",
        payQr: "",
      },
    };
  },
  mounted() {
    this.orderVo.type = this.type;
    this.orderVo.goodsId = this.goodsId;
    this.payOrder();
  },
  destroyed() {
    try {
      this.websock().colse();
    } catch (e) {}
  },
  methods: {
    payOrder() {
      this.$post("/pm/ucenter/prPay", this.orderVo).then((res) => {
        if(res.code==202){
          this.payflg = true;
        }else{
          this.orderVo.payQr = res.data.payQr;
          this.orderVo.type = res.data.type;
          this.orderVo.goodsId = res.data.goodsId;
          this.orderVo.goodsName = res.data.goodsName;
          this.orderVo.payVoucher = res.data.payVoucher;
          this.orderVo.money = res.data.money;
          this.orderVo.outTradeNo = res.data.outTradeNo;
          this.orderVo.id = res.data.id;
          this.initWebSocket(this.orderVo.outTradeNo);
        }
      });
    },
    pay() {
      this.$post("/pm/ucenter/offlinePay", this.orderVo);
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest(params) {
      let form = new FormData();
      form.append("file", params.file);
      this.$post("/pm/ucenter/upload", form).then((res) => {
        this.orderVo.payVoucher = res.url;
      });
    },
    initWebSocket(no) {
      //初始化weosocket
      const wsuri = process.env.VUE_APP_WS_API + "/pm/ws/" + no;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      //数据接收
      const redata = JSON.parse(e.data);
      if (redata.cmd == "success") {
        this.$message({
          message: "支付成功",
          type: "success",
        });

        this.$emit("payok", this.orderVo.outTradeNo);
      } else {
        this.$message({
          message: "支付失败！请重新支付",
          type: "success",
        });
      }
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 160px;
  height: 200px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 120px;
  display: block;
}
</style>