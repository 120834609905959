<template>
  <div class="bg-white py-4">
    <div class="container mx-auto flex border">
      <help-menu :menus="menus" :index="index" @selectOK="select"></help-menu>
      <div class="flex-1">
        <div class="p-5">
          <div class="tracking-widest font-bold p-2 border-b">{{ name }}</div>
          <ul class="list mt-4">
            <li v-for="item in artcles" :key="item.id" @click="goInfo(item.id)">
              <P><span class="iconfont icondot"></span>{{ item.title }}</P>
              <P>{{ item.createTime  |dateTimeFormat}}</P>
            </li>
          </ul>
          <div class="pages container mx-auto text-center mt-4 my-2">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="queryParams.pageSize"
              :total="total"
              @current-change="pageChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelpMenu from "../../components/HelpMenu.vue";
export default {
  data() {
    return {
      index: "",
      name: "",
      menus: [
        {
          name: "新手上路",
          id: "xssl",
          children: [],
        },
      ],
      artcles: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
    };
  },
  components: { HelpMenu },
  created(){
     if(this.$route.params.index){
       this.index = this.$route.params.index
       this.name = this.$route.params.name
     }
  },
  mounted() {
    this.loadCategory();
  },
  methods: {
    loadCategory() {
      this.$get("/pm/cms/category/0").then((res) => {
        this.menus[0].children = res.data;
        if (this.index == "") {
          this.index = res.data[0].id;
          this.name = res.data[0].name;
        }
        this.loadArtcles();
      });
    },
    loadArtcles() {
      this.$get(`/pm/cms/articles/${this.index}`, this.queryParams).then(
        (res) => {
          this.artcles = res.rows;
          this.total = res.total;
        }
      );
    },
    select(o) {
      var tmp = JSON.parse(o);
      this.index = tmp.id;
      this.name = tmp.name;
      this.loadArtcles();
    },
    pageChange(n) {
      this.queryParams.pageNum = n;
      this.loadArtcles();
    },
    goInfo(id) {
      this.$router.push({
        name: "help/info",
        params: {
          id: id,
          index: this.index,
          name: this.name,
          menus: this.menus,
        },
      });
    },
  },
};
</script>

<style scoped>
.list li {
  @apply h-10 leading-10 text-gray-400 flex justify-between  border-b border-dashed text-sm px-3 cursor-pointer;
}
</style>>