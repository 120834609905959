<template>
  <div class="menu w-52 bg-gray-50" style="min-height: 550px">
    <div v-for="item in menus" :key="item.id">
      <div
        class="h-12 leading-12 bg-primary text-white text-center tracking-widest font-bold"
      >
        {{ item.name }}
      </div>
      <ul class="py-2">
        <li
          v-for="item2 in item.children"
          :key="item2.id"
          :class="item2.id == $store.state.ucenterIndex ? 'active' : ''"
          @click="selectOK(item2.id, item2.path)"
        >
          <p>{{ item2.name }}</p>
          <p><i class="el-icon-arrow-right" /></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          name: "基本信息",
          path: "jbxx",
          id: "1",
          children: [
            { name: "基本信息", id: "11", path: "/ucenter/xxwh" },
            { name: "修改密码", id: "12", path: "/ucenter/xgmm" },
            // { name: "账户安全", id: "13", path: "/ucenter/zhaq" },
          ],
        },
        {
          name: "我的拍卖",
          path: "2",
          children: [
            { name: "我的消息", id: "21", path: "/ucenter/wdxx" },
            { name: "我的关注", id: "22", path: "/ucenter/wdgz" },
            { name: "我的拍品", id: "23", path: "/ucenter/wdpp" },
            { name: "我的账单", id: "24", path: "/ucenter/wdzd" },
            { name: "我的出价", id: "25", path: "/ucenter/wdcj" },
          ],
        },
      ],
    };
  },
  methods: {
    selectOK(index, url) {
      this.$store.commit("navUcenter", index);
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu li {
  @apply flex justify-between px-4 py-2 text-gray-600 cursor-pointer;
}

.menu li p:nth-child(1) {
  @apply pl-10;
}

.active {
  @apply bg-white  text-primary;
}
</style>>