<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="已报名" name="0"></el-tab-pane>
      <el-tab-pane label="拍卖中" name="1"></el-tab-pane>
      <el-tab-pane label="已获拍" name="2"></el-tab-pane>
      <el-tab-pane label="未获拍" name="3"></el-tab-pane>
    </el-tabs>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="拍品"></el-table-column>
        <el-table-column prop="ownerName" label="产权公司"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column prop="bmprice" label="保证金"></el-table-column>
        <el-table-column prop="pmksTime" label="拍卖时间" width="180px;">
          <template slot-scope="scope">
            {{
            scope.row.pmksTime | dateTimeFormat
            }} - {{
            scope.row.pmjs2Time | dateTimeFormat
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <span v-if="activeName == '0'">
              <span v-if="scope.row.bmstatus==0">
                <el-button
                  v-if="scope.row.payStatus == 0"
                  size="mini"
                  type="text"
                  @click="payBzj(scope.row)"
                >支付</el-button>
                <el-tag v-if="scope.row.payStatus == 1">支付待确认</el-tag>
                <el-tag v-if="scope.row.payStatus == 2">审核中</el-tag>
                <el-tag v-if="scope.row.payStatus == 9">已退款</el-tag>
              </span>
              <span v-if="scope.row.bmstatus==1">
                <el-button
                  v-if="scope.row.payStatus == 0"
                  size="mini"
                  type="text"
                  @click="payBzj(scope.row)"
                >支付</el-button>
                <el-tag v-if="scope.row.payStatus == 1">支付待确认</el-tag>
                <el-tag v-if="scope.row.payStatus == 2">支付已确认</el-tag>
                <el-tag v-if="scope.row.payStatus == 9">已退款</el-tag>
              </span>
              <span v-if="scope.row.bmstatus==-1">
                <el-tag v-if="scope.row.payStatus == 1">支付待确认</el-tag>
                <el-tag v-if="scope.row.payStatus == 2">支付已确认</el-tag>
                <el-tag v-if="scope.row.payStatus == 9">已退款</el-tag>
                <el-tag v-else>已拒绝</el-tag>
              </span>
            </span>
            <el-button
              v-if="activeName == '1'"
              size="mini"
              type="text"
              @click="handleUpdate(scope.row.id)"
            >参与拍卖</el-button>
            <el-button
              v-if="activeName == '2' && scope.row.winnerStatus == 0"
              size="mini"
              type="text"
              @click="payHK(scope.row)"
            >支付</el-button>
            <span v-if="activeName == '2' && scope.row.winnerStatus == 1">
              <el-tag>支付待确认</el-tag>
            </span>
            <span v-if="activeName == '2' && scope.row.winnerStatus == 2">
              <el-tag>支付已确认</el-tag>
            </span>
            <span v-if="activeName == '2' && scope.row.winnerStatus == 9">
              <el-tag>已退款</el-tag>
            </span>
            <el-button
              v-if="activeName == '2' && scope.row.tzsfile"
              size="mini"
              type="text"
              @click="tzsView(scope.row.tzsfile)"
            >通知书</el-button>
            <el-button
              v-if="activeName == '2' && scope.row.contract"
              size="mini"
              type="text"
              @click="contractView(scope.row.contract)"
            >合同</el-button>
            <span v-if="activeName == '3'">
              <el-tag type="info">拍卖已结束</el-tag>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pages container mx-auto text-center mt-4 my-2">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="queryParams.pageSize"
        :total="total"
        @current-change="pageChange"
      ></el-pagination>
    </div>
    <el-dialog :visible.sync="paybox" width="600px">
      <pm-pay :goodsId="goodsId" :type="type" @payok="payok"></pm-pay>
    </el-dialog>
    <el-dialog title="通知书" :visible.sync="viewVisible" width="700px" append-to-body>
      <span v-html="content"></span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PmPay from "../../components/PmPay.vue";
export default {
  components: { PmPay },
  data() {
    return {
      paybox: false,
      activeName: "0",
      total: 0,
      goodsId: "",
      type: "",
      queryParams: {
        pageNum: 1,
        pageSize: 5,
        status: "0"
      },
      tableData: [],
      content: "",
      viewVisible: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    payok() {
      this.paybox = false;
      var enrollForm = { goodsId: this.goodsId,type:this.type };
      this.$get("/pm/ucenter/updateEnrollOrWinner", enrollForm).then(res => {
        this.getList();
      });
    },
    getList() {
      this.tableData = [];
      this.$get("/pm/ucenter/listGoods", this.queryParams).then(res => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    pageChange(n) {
      this.queryParams.pageNum = n;
      this.getList();
    },
    handleClick() {
      this.queryParams.status = this.activeName;
      this.getList();
    },
    payBzj(row) {
      this.goodsId = row.id;
      this.type = 1;
      this.paybox = true;
    },
    payHK(row) {
      this.goodsId = row.id;
      this.type = 2;
      this.paybox = true;
    },
    contractView(url) {
      window.open(url);
    },
    cancel() {
      this.viewVisible = false;
    },
    tzsView(content) {
      this.content = content;
      this.viewVisible = true;
    },
    handleUpdate(row) {
      const goodsId = row.id;
      this.$store.commit("nav", 1);
      this.$router.push({
        path: "/goods/info",
        query: {
          goodsId: goodsId
        }
      });
    }
  }
};
</script>

<style>
</style>