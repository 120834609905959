import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { post, get } from './utils/http'
//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;

import './assets/css/tailwind.css';
import './assets/css/rest.css';
import './assets/font/iconfont.css';

import ElementUI from 'element-ui';
import './assets/css/element-variables.scss';
Vue.use(ElementUI);

Vue.config.productionTip = false


import moment from 'moment';

// 定义全局的时间过滤器
Vue.filter('dateFormat', function(dateStr, formatStr = 'YYYY-MM-DD') {
    // 在过滤器的处理函数中，最后，必须 return 一个东西，这样，才是一个合法的过滤器
    if (dateStr === null) {
        return ''
    }
    return moment(dateStr).format(formatStr)
})

Vue.filter('dateTimeFormat', function(dateStr, formatStr = 'YYYY-MM-DD HH:mm:ss') {
    // 在过滤器的处理函数中，最后，必须 return 一个东西，这样，才是一个合法的过滤器
    if (dateStr === null) {
        return ''
    }
    return moment(dateStr).format(formatStr)
})


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')