<template>
  <div class="menu w-52 bg-gray-50" style="min-height: 550px">
    <div v-for="item in menus" :key="item.id">
      <div
        class="h-12 leading-12 bg-primary text-white text-center tracking-widest font-bold"
      >
        {{ item.name }}
      </div>
      <ul class="py-2">
        <li
          v-for="item2 in item.children"
          :key="item2.id"
          :class="item2.id == index ? 'active' : ''"
          @click="selectOK(item2.id,item2.name)"
        >
          <p>{{ item2.name }}</p>
          <p><i class="el-icon-arrow-right" /></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["menus", "index"],
  created() {
  },
  methods:{
    selectOK(id,name){
      this.$emit("selectOK",JSON.stringify({name:name,id:id}))
    }
  }
};
</script>

<style lang="scss" scoped>
.menu li {
  @apply flex justify-between px-4 py-2 text-gray-600 cursor-pointer;
}

.menu li p:nth-child(1) {
  @apply pl-10;
}

.active {
  @apply bg-white  text-primary;
}
</style>>