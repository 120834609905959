<template>
  <div class="home">
    <my-header></my-header>
    <div class="">
      <router-view />
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import myHeader from "@/components/Header";
import myFooter from "@/components/Footer";
export default {
  name: "Home",
  components: {
    myHeader,
    myFooter,
  },
};
</script>
