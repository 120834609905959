<template>
  <div class="bg-white py-4">
    <div class="container mx-auto grid grid-cols-2 gap-5 my-2">
      <notice-item
        v-for="goodNotice in goodNoticeList"
        :key="goodNotice.id"
        :goodNotice="goodNotice"
        @selectOK="selectNotice(goodNotice.id,goodNotice.goodsId)"
      />
    </div>
    <div class="pages container mx-auto text-center mt-4 my-2">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="queryParams.pageSize"
        :total="total"
        @current-change="pageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import HelpMenu from "../../components/HelpMenu.vue";
import NoticeItem from "../../components/NoticeItem.vue";
export default {
  data() {
    return {
      index: "",
      name: "",
      menus: [
        {
          name: "新手上路",
          id: "xssl",
          children: []
        }
      ],
      artcles: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 16,
        isRelease: 1, //已发布
        goodsStatus: "", //商品状态集合
        type: 2 //信息类型
      },
      goodNoticeList: [] //拍卖公告
    };
  },
  components: { HelpMenu, NoticeItem },
  created() {},
  mounted() {
    this.getGoodNoticeList(); //拍卖公告
  },
  methods: {
    selectNotice(noticeId, goodsId) {
      this.$store.commit("nav", 3);
      this.$router.push({
        path: "/notice/info",
        query: {
          goodsId: goodsId
        }
      });
    },
    //拍卖公告
    getGoodNoticeList() {
      this.$get(`/pm/auction/getPmGoodNoticeList`, this.queryParams).then(res => {
        if (res.code === 200) {
          console.log("拍卖公告");
          console.log(res);
          this.goodNoticeList = res.rows;
          this.total=res.total;
        }
      });
    },
    pageChange(n) {
      this.queryParams.pageNum = n;
      this.getList();
    },
  }
};
</script>

<style scoped>
.list li {
  @apply h-10 leading-10 text-gray-400 flex justify-between  border-b border-dashed text-sm px-3 cursor-pointer;
}
</style>>