<template>
  <div class="flex items-center justify-between   text-gray-400">
    <div class="step1">
      <div class="w-12 h-12 rounded-full bg-gray-200  text-center leading-12">
        <span class="iconfont icon-baoming text-primary" style="font-size: 24px" />
      </div>
      <div class="mt-2 text-sm">注册认证</div>
    </div>
    <div class="step2">
      <span
        class="iconfont icon-icon "
        style="font-size: 36px"
      />
    </div>
  <div class="step1">
      <div class="w-12 h-12 rounded-full  bg-gray-200 text-center leading-12">
        <span class="iconfont icon-fukuan  text-primary" style="font-size: 24px" />
      </div>
      <div class="mt-2 text-sm">拍前准备</div>
    </div>
    <div class="step2">
      <span
        class="iconfont icon-icon "
        style="font-size: 36px"
      />
    </div>
    <div class="step1">
      <div class="w-12 h-12 rounded-full bg-gray-200 text-center leading-12">
        <span
          class="iconfont icon-baozhengjin text-primary"
          style="font-size: 24px"
        />
      </div>
      <div class="mt-2 text-sm">交保证金</div>
    </div>
    <div class="step2">
      <span
        class="iconfont icon-icon "
        style="font-size: 36px"
      />
    </div>
    <div class="step1">
      <div class="w-12 h-12 rounded-full bg-gray-200 text-center leading-12">
        <span
          class="iconfont icon-4-72 text-primary"
          style="font-size: 24px"
        />
      </div>
      <div class="mt-2 text-sm">出价竞价</div>
    </div>
    <div class="step2">
      <span
        class="iconfont icon-icon "
        style="font-size: 36px"
      />
    </div>
    <div class="step1">
      <div class="w-12 h-12 rounded-full   bg-gray-200  text-center leading-12">
        <span
          class="iconfont icon-chenggong text-primary"
          style="font-size: 24px"
        />
      </div>
      <div class="mt-2 text-sm">办理交接</div>
    </div>
    <div class="step2">
      <span
        class="iconfont icon-icon"
        style="font-size: 36px"
      />
    </div>
   
    <div class="step1">
      <div class="w-12 h-12 rounded-full  bg-gray-200 text-center leading-12">
        <span class="iconfont icon-jingjia text-primary" style="font-size: 24px" />
      </div>
      <div class="mt-2 text-sm">线下交割</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>