<template>
  <div class="w-full h-full bg-white">
    <div
      class="text-2xl text-gray-600 pb-3 border-b-2 w-full text-center border-red-500"
    >
      用户登录
    </div>
    <div class="w-full pr-4">
      <el-form
        :model="loginForm"
        status-icon
        :rules="loginRules"
        ref="loginForm"
        label-width="80px"
        class="mt-5"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="loginForm.username"
            autocomplete="off"
            placeholder="用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            autocomplete="off"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <div class="flex justify-between items-center">
            <el-input
              v-model="loginForm.code"
              auto-complete="off"
              placeholder="验证码"
              @keyup.enter.native="handleLogin"
              class="w-24"
            >
            </el-input>
            <img :src="codeUrl" @click="getCode" class="h-9 w-24" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            style="width: 100%"
            @click.native.prevent="handleLogin"
          >
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      codeUrl: "",
      cookiePassword: "",
      loginForm: {
        username: "",
        password: "",
        code: "",
        uuid: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" },
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
        ],
        code: [
          { required: true, trigger: "change", message: "验证码不能为空" },
        ],
      },
      loading: false,
      redirect: undefined,
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    getCode() {
      this.$get("/captchaImage").then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.loginForm.uuid = res.uuid;
      });
    },
    handleLogin() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$post("/pm/login/login", this.loginForm)
            .then((res) => {
              if (res.code == 200) {
                this.$store.commit("setToken", res.token);
                this.$post("/pm/login/userInfo", this.loginForm).then((res) => {
                  this.$store.commit("setUser", res.data);
                  this.$emit("loginok","");
                });
              } else {
                this.loading = false;
                this.getCode();
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            })
            .catch(() => {
              this.loading = false;
              this.getCode();
            });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
  },
};
</script>
