<template>
   <div
        class="special my-4 h-64 flex justify-between items-center border  shadow-sm" @click="selectOK"
      >
        <img :src="group.image" class="h-full w-140 object-cover" />
        <div class="flex flex-col h-64 flex-1 ml-10 justify-between py-4">
          <p class="font-bold text-xl">{{group.groupName}}</p>
          <p class="text-sm text-gray-500">{{group.goodsinfo}}</p>
          <p class="text-sm text-gray-500">{{group.beginTime}} - {{group.endTime}}</p>
          <p class="text-sm text-gray-500 font-semibold">
            共有 <span class="text-primary">{{group.pmGoodsList.length}}</span> 件标的
            <!-- <span class="ml-5 text-primary">xx</span>人围观 -->
          </p>
          <ul class=" grid grid-cols-5 mt-5">
            <li v-for="goodsObj in group.pmGoodsList" :key="goodsObj.id" >
              <img
                :src="goodsObj.image"
                class="h-24 w-24 object-cover"
              />
            </li>
          </ul>
        </div>
      </div>
</template>

<script>
export default {
  props: ['groupObj'],
  data() {
    return {
      group: this.groupObj
    };
  },
   methods:{
     selectOK(){
       this.$emit("selectOK","");
     }
   }
};
</script>

<style>

</style>