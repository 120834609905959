<template>
  <div>
    <el-form
      ref="ruleForm"
      :rules="rules"
      :model="form"
      label-width="300px"
      size="medium"
      style="width: 600px"
    >
      <el-form-item label="绑定手机" prop="mobile">
        <el-input v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="绑定邮箱" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    var checkphone = (rule, value, callback) => {
      if (!value) {
        return false;
      } else{
        let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
        if (!phoneReg.test(value)) {
        // 引入methods中封装的检查手机格式的方法
        return callback(new Error("请输入正确的手机号!"));
      } else {
        callback();
      }
      }
    };
    return {
      form: {
        mobile: "",
        email: "",
      },
      rules: {
        mobile: [
          {
            validator: checkphone,
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/pm/login/restPwd", this.form).then((res) => {
            this.$message({
                message: "密码修改成功！",
                type: "success"
              });
          });
        } else {
          return false;
        }
      });
    }
  },
};
</script>

<style>
</style>