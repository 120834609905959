<template>
  <div class="w-full h-full bg-white">
    <div class="h-10 bg-gray-100 flex justify-between">
      <div
        class="container mx-auto flex justify-between text-xs text-gray-400 items-center"
      >
        <p>你好， 欢迎来到冀中能源集团电子拍卖管理平台</p>
        <ul class="flex items-center">
          <li>客服电话：400-800-1234</li>
          <li class="ml-10" @click="goHome">首页</li>
        </ul>
      </div>
    </div>
    <div
      class="container mx-auto h-28 flex items-center justify-between text-sm"
    >
      <img src="@/assets/logo.png" class="h-10" />
      <div>
        没有账号？
        <span class="text-blue-400" @click="goReg"> 立即注册 </span>
      </div>
    </div>
    <div class="border-b"></div>
    <div class="backimage w-full h-135 bg-gray-100">
      <div class="h-full container mx-auto flex justify-end items-center">
        <div
          class="w-96 bg-gray-100 flex flex-col justify-between items-center border rounded-lg shadow-lg p-4"
        >
          <div
            class="text-2xl text-gray-600 pb-3 border-b-2 w-full text-center border-red-500"
          >
            用户登录
          </div>
          <div class="w-full pr-4">
            <el-form
              :model="loginForm"
              status-icon
              :rules="loginRules"
              ref="loginForm"
              label-width="80px"
              class="mt-5"
            >
              <el-form-item label="用户名" prop="username">
                <el-input
                  v-model="loginForm.username"
                  autocomplete="off"
                  placeholder="用户名"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  type="password"
                  v-model="loginForm.password"
                  autocomplete="off"
                  placeholder="密码"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code">
                <div class="flex justify-between items-center">
                  <el-input
                    v-model="loginForm.code"
                    auto-complete="off"
                    placeholder="验证码"
                    @keyup.enter.native="handleLogin"
                    class="w-24"
                  >
                  </el-input>
                  <img :src="codeUrl" @click="getCode" class="h-9 w-24" />
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  :loading="loading"
                  size="medium"
                  type="primary"
                  style="width: 100%"
                  @click.native.prevent="handleLogin"
                >
                  <span v-if="!loading">登 录</span>
                  <span v-else>登 录 中...</span>
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto text-xs text-gray-400 text-center my-5">
      © 2018- 2021 河北中瑞博雅科技限公司版权所有
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codeUrl: "",
      cookiePassword: "",
      loginForm: {
        username: "",
        password: "",
        code: "",
        uuid: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" },
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
        ],
        code: [
          { required: true, trigger: "change", message: "验证码不能为空" },
        ],
      },
      loading: false,
      redirect: undefined,
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    goHome() {
      this.$store.commit("nav", 0);
      this.$router.push("/");
    },
    goLogin() {
      this.$router.push("/login");
    },
    goReg() {
      this.$router.push("/register");
    },
    getCode() {
      this.$get("/captchaImage").then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.loginForm.uuid = res.uuid;
      });
    },
    handleLogin() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$post("/pm/login/login", this.loginForm)
            .then((res) => {
              if (res.code == 200) {
                this.$store.commit("setToken",  res.token);
                this.$post("/pm/login/userInfo", this.loginForm).then((res) => {
                    this.$store.commit("setUser",  res.data);
                });
                this.$router.push("/");
              } else {
                this.loading = false;
                this.getCode();
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            })
            .catch(() => {
              this.loading = false;
              this.getCode();
            });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style>
.backimage {
  background-image: url(../assets/images/login-back.png);
  position: relative;
}
</style>