<template>
  <div class="bg-white py-4">
    <div class="container mx-auto flex border">
      <help-menu :menus="menus" :index="index" @selectOK="backList"></help-menu>
      <div class="flex-1">
        <div class="p-5">
          <div class="tracking-widest font-bold p-2 border-b">{{name}}</div>
          <div class="p-4 text-center">
            <p class="title text-lg">{{article.title}}</p>
            <p class="title text-gray-400 text-sm">时间：{{article.createTime |dateTimeFormat}}</p>
            <div class="border-b border-dashed mt-2"></div>
            <div class="text-sm text-gray-400 text-left pt-5" v-html="article.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelpMenu from "../../components/HelpMenu.vue";
export default {
  data() {
    return {
      id:0,
      name: "",
      index: "yhzy",
      menus: [],
      article: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.name = this.$route.params.name;
    this.index = this.$route.params.index;
    this.menus = this.$route.params.menus;
    this.loadArticle();
  },
  methods: {
    loadArticle() {
       this.$get(`/pm/cms/article/${this.id}`).then((res) => {
        this.article = res.data;
      });
    },
    backList() {
      this.$router.push({
        name: "help",
        params: {
          index: this.index,
          name: this.name
        },
      });
    },
  },
  components: { HelpMenu },
};
</script>

<style scoped>
</style>>