<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="保证金" name="1"></el-tab-pane>
      <el-tab-pane label="货款" name="2"></el-tab-pane>
      <el-tab-pane label="退保" name="3"></el-tab-pane>
    </el-tabs>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="createTime" label="日期">
          <template slot-scope="scope">{{scope.row.createTime|dateTimeFormat}}</template>
        </el-table-column>
        <el-table-column prop="goodsName" label="拍品"></el-table-column>
        <el-table-column prop="ownerName" label="产权公司"></el-table-column>
        <el-table-column prop="money" label="金额"></el-table-column>
        <!-- <el-table-column prop="status" label="备注">
          <template slot-scope="scope">
          <span v-if="scope.row.status==2">已退款</span>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="pages container mx-auto text-center mt-4 my-2">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="queryParams.pageSize"
        :total="total"
        @current-change="pageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "1",
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 5,
        type: "1"
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableData = [];
      this.$get("/pm/ucenter/listPays", this.queryParams).then(res => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    pageChange(n) {
      this.queryParams.pageNum = n;
      this.getList();
    },
    handleClick() {
      this.queryParams.type = this.activeName;
      this.getList();
    }
  }
};
</script>

<style>
</style>