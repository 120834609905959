<template>
<div class="goods bg-white border flex flex-col justify-between shadow-md hover:border-primary " @click="selectOK">
    <div class="relative">
      <img
        :src="hot.image"
        class="h-48 w-full object-cover"
      />
      
      <!-- <div
        class="w-20 h-8 leading-8 text-center absolute top-5 bg-primary rounded-r-full text-white text-xs -left-1 border-l tracking-widest"
        v-if="hot.pmtype == '1'"
      >
        自由报价期
      </div>
      <div
        class="w-20 h-8 leading-8 text-center absolute top-5 bg-primary rounded-r-full text-white text-xs -left-1 border-l tracking-widest"
        v-else
      >
        竞拍报价期
      </div>-->
    </div> 
    <p class="px-2 font-bold my-1 truncate " :title="hot.name +'  -  '+ hot.goodsCode">{{hot.name +'  -  '+ hot.goodsCode}}</p>
    <div
        class="h-6 leading-6    w-full text-primary text-sm px-2"
      >
        <!-- 结束时间： 2021-01-19 10:00:00 -->
        开始时间：{{hot.pmksTime}}
        
      </div>
    <div class="flex flex-wrap px-2 text-xs leading-6 text-gray-500">
      <div class="w-5/6">项目编号：{{hot.projectCode}}</div>
      <div class="w-1/6 text-right">11件</div>
      <div class="w-4/6">
        当前报价：<span class="text-primary">￥{{hot.maxprice / 10000}} 万</span>
      </div>
      <div class="w-2/6 text-right">
        <i class="el-icon-location-outline mr-1" />{{hot.provinceName}}
      </div>
      <div class="w-4/6">起拍价格：￥{{hot.price / 10000}} 万</div>
      <div class="w-2/6 text-right">{{hot.pricecount}}次出价</div>
    </div>
    <div
      class="h-10 leading-10 border-t border-dashed mt-2 mx-2 border-gray-400 justify-between box-border text-xs text-gray-500"
    >
      产权归属：{{hot.ownerName}}
    </div>
  </div>
</template>

<script>
export default {
   props: ['hotGoods'],
  data() {
    return {
      hot: this.hotGoods
    };
  },
   methods:{
     selectOK(){
       this.$emit("selectOK","");
     },
   }
};
</script>

<style>
</style>