<template>
  <div class="container mx-auto">
    <div class="flex justify-between">
      <div class="flex-1 border">
        <div class="mt-4 flex justify-between items-start">
          <div class="w-96 ml-4">
            <img :src="goodsInfo.image" class="w-96 h-96 object-cover" />
          </div>
          <div class="flex-1 relative">
            <p class="ml-4 text-lg font-bold truncate">{{ goodsInfo.name }}</p>
            <p class="mt-1 ml-4 font-sm text-gray-400">
              项目编号：{{ goodsInfo.goodsCode }}
            </p>
            <div class="flex items-center justify-between mt-2 h-10">
              <div
                class="ml-4  bg-green-600 text-sm text-white h-8 leading-8 px-4 box-border" v-if="goodsInfo.node==3 ||goodsInfo.node==1 "
              >
                {{ goodsInfo.nodeName }}<span>...</span>
              </div>
              <div v-else
                class="ml-4 bg-primary text-sm text-white h-8 leading-8 px-4 box-border"
              >
                {{ goodsInfo.nodeName }}
              </div>
              <div class="ml-4 mr-6">
                <flip-countdown
                  :deadline="goodsInfo.deadLine"
                  class="w-44"
                  v-if="goodsInfo.node == 3"
                />
              </div>
            </div>
            <div class="h-56 bg-gray-200 my-1 text-sm py-2 px-5 leading-8">
              <div class="grid grid-cols-2">
                <p>
                  当前价：<span class="text-lg font-bold">
                    ￥ {{ goodsInfo.maxprice }}</span
                  >
                </p>
                <p>
                  出价人：
                  <span v-if="goodsInfo.enrollCode">
                    {{ goodsInfo.enrollCode }}</span
                  >
                  <span v-else> 暂无出价 </span>
                </p>
                <p>
                  起拍价：<span> ￥ {{ goodsInfo.price }}</span>
                </p>
                <p>
                  保证金：<span class="text-primary">
                    ￥ {{ goodsInfo.bmprice }}</span
                  >
                </p>
                <p>
                  加价幅度：<span> ￥ {{ goodsInfo.addRange }}</span>
                </p>
                <p>延时周期：<span class="text-primary"> 5分钟</span></p>
                <p>
                  报名开始：<span>{{ goodsInfo.bmksTime }} </span>
                </p>
                <p>
                  报名结束：<span>{{ goodsInfo.bmjsTime }}</span>
                </p>
                <p>
                  拍卖开始：<span>{{ goodsInfo.pmksTime }} </span>
                </p>
                <p>
                  拍卖结束：<span>{{ goodsInfo.pmjs2Time }}</span>
                </p>
                <p class="mt-2">
                  <el-input-number
                    :min="goodsInfo.maxprice + goodsInfo.addRange"
                    v-model="queryParam.money"
                    :step="goodsInfo.addRange"
                    :disabled="ppDisable"
                  ></el-input-number>
                </p>
                <p
                  class="font-bold tracking-widest bg-red-300 text-center mt-2 text-white h-10 leading-10 cursor-pointer"
                  v-if="buttonDisable"
                >
                  {{ buttonText }}
                </p>
                <p
                  class="font-bold tracking-widest bg-primary text-center mt-2 text-white h-10 leading-10 cursor-pointer"
                  v-else
                  @click="myClick(actionName)"
                >
                  {{ buttonText }}
                </p>
              </div>
            </div>
            <ul class="tips h-12 p-2 mx-4 border-b flex">
              <li>{{ goodsInfo.visit }} 访问量</li>
              <li>{{ goodsInfo.pricecount }} 次出价</li>
              <li>{{ goodsInfo.visit2 }} 次围观</li>
              <li v-if="$store.state.token != ''" class="cursor-pointer">
                <span
                  class="iconfont icon-guanzhu"
                  v-if="isFollow == 0"
                  @click="follow(1)"
                  >未关注</span
                >
                <span
                  class="iconfont icon-guanzhu text-primary"
                  v-else
                  @click="follow(0)"
                  >已关注</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-4">
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane
              :label="item.type==1?'标的详情':'竞价须知'"
              :name="item.type==1?'xiangqing':'xuzhi'"
              style="padding: 30px"
              v-for="item in goodsExtInfos"
              :key="item.id"
            >
              <div v-if="item.content != null" v-html="item.content"></div>
              <div v-else>暂无内容</div>
            </el-tab-pane>
            <el-tab-pane label="竞价公告" name="gonggao" style="padding: 30px">
              <div
                v-if="goodsNoticeInfo"
                v-html="goodsNoticeInfo.content"
              ></div>
              <div v-else>暂无公告</div>
            </el-tab-pane>
            <el-tab-pane label="竞价记录" name="baojia" style="padding: 30px">
              <ul class="pricelist px-5">
                <li class="flex">
                  <p class="w-20">状态</p>
                  <p class="w-20">竞买号</p>
                  <p class="w-20">价格</p>
                  <p class="w-40">时间</p>
                </li>
                <li
                  class="flex"
                  v-for="(item, index) in priceList"
                  :key="item.id"
                >
                  <p class="w-20">
                    <span v-if="index == 0">
                      <span v-if="goodsInfo.node == 4">中拍</span>
                      <span v-else>领先</span>
                    </span>
                    <span v-else>出局</span>
                  </p>
                  <p class="w-20">{{ item.enrollCode }}</p>
                  <p class="w-20">{{ item.money }}</p>
                  <p class="w-40">{{ item.createTime }}</p>
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="w-64 border-t border-b border-r px-3 py-5">
        <p class="font-bold text-sm mb-3">拍品公告</p>
        <p
          class="text-center text-gray-400 text-sm m-2 mb-6 cursor-pointer"
          @click="selectNotice(goodsInfo.id)"
        >
          查看拍品公告
        </p>
        <p class="border-t"></p>
        <p class="text-center m-2">
          <span class="text-primary">热拍</span>商品
        </p>
        <ul class="text-sm text-gray-400 flex items-center flex-col">
          <li
            v-for="hotGoods in hotGoodsList"
            :key="hotGoods.id"
            class="mb-6"
            @click="selectGoods(hotGoods.id)"
          >
            <img :src="hotGoods.image" class="w-52 h-52 object-cover" />
            <div class="mt-1 flex justify-between">
              <P
                >当前报价:<span class="text-primary"
                  >￥ {{ hotGoods.maxprice }}</span
                ></P
              >
              <P
                >出价<span class="text-primary">{{ hotGoods.pricecount }}</span
                >次</P
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog :visible.sync="paybox" width="600px">
      <pm-pay :goodsId="goodsInfo.id" :type="1" @payok="payBack"></pm-pay>
    </el-dialog>
    <el-dialog :visible.sync="loginbox" width="400px">
      <pm-login @loginok="loginBack" />
    </el-dialog>
  </div>
</template>

<script>
import FlipCountdown from "../../components/FlipCountdown.vue";
import PmLogin from "../../components/PmLogin.vue";
import PmPay from "../../components/PmPay.vue";
export default {
  components: { FlipCountdown, PmLogin, PmPay },
  data() {
    return {
      activeName: "xiangqing",
      queryParam: {
        goodsId: 0,
        id: "",
        type: 1,
        agency: "",
        money: 0,
      },
      paybox: false,
      loginbox: false,
      buttonText: "登陆后查看详情",
      buttonDisable: true,
      ppDisable: true,
      isFollow: 0,
      goodsInfo: {}, //商品详情
      priceList: [],
      goodsExtInfos: [], //扩展信息
      hotGoodsList: [], //热拍商品
      goodsNoticeInfo: {},
      actionName: "login",
      enroll: null,
      websock: null,
    };
  },
  mounted() {
    this.queryParam.id = this.$route.query.goodsId;
    this.queryParam.goodsId = this.$route.query.goodsId;
    this.loadGoodsInfo();
    this.loadGoodsExtInfo();
    this.loadGoodsPrices();
    this.loadNotice();
    this.checkFollow();
    this.visit();
  },
  destroyed() {
    try {
      this.websock.colse();
    } catch (e) {}
  },
  methods: {
    myClick(name) {

      this.buttonDisable = true;
      this[name]();
    },
    initButton() {
      if (this.$store.state.token == "") {
        this.buttonText = "登陆后查看详情";
        this.buttonDisable = false;
        this.actionName = "login";
      } else {
        if (
          this.goodsInfo.node == 0 ||
          this.goodsInfo.node == 2 ||
          this.goodsInfo.node == 4
        ) {
          this.buttonText = this.goodsInfo.nodeName;
          this.buttonDisable = true;
          this.actionName = "wait";
        } else if (this.goodsInfo.node == 1) {
          this.checkEnroll();
        } else if (this.goodsInfo.node == 3) {
          if (this.websock == null) {
            this.initWebSocket(
              "GOODS::" + this.goodsInfo.id + "::" + this.$store.state.user.uid
            );
          }
          this.checkEnroll2();
        }
      }
    },
    //加载基础信息
    loadGoodsInfo() {
      this.$get("/pm/auction/getPmGoodsInfoById", this.queryParam).then(
        (res) => {
          if (res.code === 200) {
            this.goodsInfo = res.data;
            this.queryParam.money =
              this.goodsInfo.maxprice + this.goodsInfo.addRange;
            this.initButton();
            this.queryParam.agency = this.goodsInfo.agency;
            this.loadHotGoodsInfo();
          }
        }
      );
    },
    //加载扩展信息
    loadGoodsExtInfo() {
      this.$get("/pm/auction/getPmGoodsExtInfo", this.queryParam).then(
        (res) => {
          if (res.code === 200) {
            this.goodsExtInfos = res.data;
          }
        }
      );
    },
    //出价记录
    loadGoodsPrices() {
      this.$get("/pm/auction/getPmGoodsPriceList", this.queryParam).then(
        (res) => {
          if (res.code === 200) {
            this.priceList = res.data;
          }
        }
      );
    },
    //热拍商品
    loadHotGoodsInfo() {
      this.$get("/pm/auction/getHotGoodsList", this.queryParam).then((res) => {
        if (res.code === 200) {
          this.hotGoodsList = res.data;
        }
      });
    },
    loadNotice() {
      this.$get(`/pm/auction/getPmGoodNoticeInfo`, this.queryParam).then(
        (res) => {
          if (res.code == 200) {
            this.goodsNoticeInfo = res.data;
          }
        }
      );
    },
    // 查看公告
    selectNotice(goodsId) {
      this.$store.commit("nav", 3);
      this.$router.push({
        path: "/notice/info",
        query: {
          goodsId: goodsId,
        },
      });
    },
    // 左侧热拍商品栏的链接
    selectGoods(goodsId) {
      try {
        this.websock.colse();
      } catch (e) {}
      this.queryParam.id = goodsId;
      this.queryParam.goodsId = goodsId;
      this.loadGoodsInfo();
      this.loadGoodsExtInfo();
      this.loadGoodsPrices();
      this.loadNotice();
      this.checkFollow();
      this.visit();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //访问
    visit() {
      this.$get(`/pm/ucenter/visit`, this.queryParam);
    },
    checkFollow() {
      this.$get("/pm/ucenter/checkFollow", this.queryParam).then((res) => {
        this.isFollow = res.data;
      });
    },
    checkEnroll() {
      this.$get("/pm/ucenter/checkEnroll", this.queryParam).then((res) => {
        this.enroll = res.data;
        if (this.enroll) {
          if (this.enroll.status == 1) {
            this.ppDisable = true;
            this.buttonText = "报名成功等待竞拍";
            this.buttonDisable = true;
            this.actionName = "wait";
          }
          if (this.enroll.status == 0) {
            if (this.enroll.payStatus == 0) {
              this.ppDisable = true;
              this.buttonText = "支付保证金";
              this.buttonDisable = false;
              this.actionName = "mypay";
            } else {
              this.ppDisable = true;
              this.buttonText = "已支付待确认";
              this.buttonDisable = true;
              this.actionName = "wait";
            }
          }
          if (this.enroll.status == -1) {
            this.ppDisable = true;
            this.buttonText = "报名被拒绝";
            this.buttonDisable = true;
            this.actionName = "wait";
          }
        } else {
          this.ppDisable = true;
          this.buttonText = "我要报名";
          this.buttonDisable = false;
          this.actionName = "myenroll";
        }
      });
    },
    checkEnroll2() {
      this.$get("/pm/ucenter/checkEnroll", this.queryParam).then((res) => {
        this.enroll = res.data;
        if (this.enroll && this.enroll.status == 1) {
          this.ppDisable = false;
          this.buttonDisable = false;
          this.buttonText = "我要出价";
          this.actionName = "myprice";
        } else {
          this.ppDisable = true;
          this.buttonText = "您正在围观...";
          this.buttonDisable = true;
          this.actionName = "wait";
        }
      });
    },
    follow(n) {
      this.queryParam.type = n;
      this.$get("/pm/ucenter/follow", this.queryParam).then((res) => {
        this.checkFollow();
      });
    },
    login() {
      this.buttonDisable = true;
      this.loginbox = true;
    },
    mypay() {
      this.buttonDisable = true;
      this.paybox = true;
    },
    wait() {
      console.log("*");
    },
    loginBack() {
      this.loginbox = false;
      this.buttonDisable = false;
      this.checkFollow();
      this.initButton();
    },
    payBack() {
      this.paybox = false;
      this.buttonDisable = false;
      this.checkEnroll();
    },
    myenroll() {
      this.$get("/pm/ucenter/enroll", this.queryParam).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "报名成功",
            type: "success",
          });
          this.initButton();
        }
      });
    },
    myprice() {
      const priceParam = {
        projectId: this.goodsInfo.projectId,
        goodsId: this.goodsInfo.id,
        enrollId: this.enroll.id,
        comid: this.enroll.comid,
        uid: this.enroll.uid,
        money: this.queryParam.money,
        enrollCode: this.enroll.enrollCode,
      };
      // 报 价
      this.$post("/pm/ucenter/price", priceParam).then((res) => {
        this.buttonDisable = false;
      });
    },
    initWebSocket(no) {
      //初始化weosocket
      const wsuri = process.env.VUE_APP_WS_API + "/pm/ws/" + no;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      console.log("message get ...");
      //数据接收
      const redata = JSON.parse(e.data);
      try {
        if (parseInt(redata.msgId) == this.enroll.id) {
          this.$message({
            message: redata.msgTxt,
            type: "success",
          });
        }
      } catch (e) {}
      this.loadGoodsInfo(); //商品详情
      this.loadGoodsPrices(); //价格列表
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
    },
  },
};
</script>

<style scoped>
.tips {
  @apply px-4;
}
.tips li {
  @apply text-sm text-gray-400 mr-2 px-2 h-8 leading-8 border-r;
}

.pricelist li {
  @apply border-b  h-10 leading-10 flex justify-between px-10 text-sm border-dashed text-gray-400;
}
.pricelist li p {
  @apply text-center;
}

.pricelist li:nth-child(2) {
  @apply text-primary font-bold;
}
</style>>