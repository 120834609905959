import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        redirect: '/index',
        children: [{
                path: '/index',
                name: 'index',
                component: () =>
                    import ('../views/index/index.vue')
            },
            {
                path: '/group',
                name: 'group',
                component: () =>
                    import ('../views/group/index.vue')
            },
            {
                path: '/goods',
                name: 'goods',
                component: () =>
                    import ('../views/goods/index.vue')
            },
            {
                path: '/goods/info',
                name: 'goods/info',
                component: () =>
                    import ('../views/goods/info2.vue')
            },
            {
                path: '/group/info',
                name: 'group/info',
                component: () =>
                    import ('../views/group/info.vue')
            },
            {
                path: '/notice',
                name: 'notice',
                component: () =>
                    import ('../views/notice/index.vue')
            },
            {
                path: '/notice2',
                name: 'notice2',
                component: () =>
                    import ('../views/notice/index2.vue')
            },
            {
                path: '/notice/info',
                name: 'notice/info',
                component: () =>
                    import ('../views/notice/info.vue')
            },
            {
                path: '/help',
                name: 'help',
                component: () =>
                    import ('../views/help/index.vue')
            }, {
                path: '/help/info',
                name: 'help/info',
                component: () =>
                    import ('../views/help/info.vue')
            },
            {
                path: '/ucenter',
                name: 'ucenter',
                redirect: '/ucenter/xxwh',
                component: () =>
                    import ('../views/ucenter/index.vue'),
                children: [{
                        path: '/ucenter/xxwh',
                        name: 'ucenter/xxwh',
                        component: () =>
                            import ('../views/ucenter/xxwh.vue')
                    },
                    {
                        path: '/ucenter/xgmm',
                        name: 'ucenter/xgmm',
                        component: () =>
                            import ('../views/ucenter/xgmm.vue')
                    },
                    {
                        path: '/ucenter/zhaq',
                        name: 'ucenter/zhaq',
                        component: () =>
                            import ('../views/ucenter/zhaq.vue')
                    },
                    {
                        path: '/ucenter/wdxx',
                        name: 'ucenter/wdxx',
                        component: () =>
                            import ('../views/ucenter/wdxx.vue')
                    }, {
                        path: '/ucenter/wdgz',
                        name: 'ucenter/wdgz',
                        component: () =>
                            import ('../views/ucenter/wdgz.vue')
                    }, {
                        path: '/ucenter/wdpp',
                        name: 'ucenter/wdpp',
                        component: () =>
                            import ('../views/ucenter/wdpp.vue')
                    },
                    {
                        path: '/ucenter/wdzd',
                        name: 'ucenter/wdzd',
                        component: () =>
                            import ('../views/ucenter/wdzd.vue')
                    },
                    {
                        path: '/ucenter/wdcj',
                        name: 'ucenter/wdcj',
                        component: () =>
                            import ('../views/ucenter/wdcj.vue')
                    }

                ]
            }

        ]
    }, {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/login.vue')
    }, {
        path: '/register',
        name: 'register',
        component: () =>
            import ('../views/register.vue')
    }

]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router