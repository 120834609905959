import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {},
        token: '',
        navIndex: 0,
        ucenterIndex: 11,
        qjKey:""
    },
    mutations: {
        nav(state, index) {
            state.navIndex = index
        },
        navUcenter(state, index) {
            state.ucenterIndex = index
        },
        setToken(state, token) {
            state.token = token
        },
        setUser(state, user) {
            state.user = user
        }
    },
    actions: {},
    modules: {},
    plugins: [createPersistedState({
        storage: window.localStorage
    })]
})