<template>
  <div class="border bg-gray-100 p-4 flex justify-between hover:border-primary" @click="selectOK">
      <div class="h-40 w-72 ">
          <img :src="notice.goodImage"  class="h-40 w-72 object-cover"/>
      </div>
      <div class="px-3 flex justify-between  flex-col w-full" >
        <p class="font-black" v-html="notice.title + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'">
        </p>
        <p class=" text-sm text-gray-500 my-2">
            {{notice.decribe}}
        </p>
        <div class="flex text-sm text-gray-500 flex justify-between">
                <p>来自 <span class="text-primary">{{notice.ownerName}}</span></p> <p>{{notice.ggksTime}}</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: ['goodNotice'],
    data() {
      return {
        notice: this.goodNotice
      };
    },
    methods:{
     selectOK(){
       this.$emit("selectOK","");
     },
   }
}
</script>

<style>

</style>