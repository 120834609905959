<template>
  <div class="bg-white py-2">
    <div class="banner container mx-auto h-96 flex justify-between">
      <div class="h-full w-56 bg-gray-50 p-4 flex justify-between flex-col">
        <div>
          <div class="flex justify-between items-baseline">
            <p class="text-lg">标的物类型</p>
            <p class="text-xs  hover:text-primary cursor-pointer" @click="toGoods(0,null)">更多</p>
          </div>
          <ul class="flex flex-wrap text-sm text-gray-500 mt-4 cursor-pointer">
            <li
              v-for="category in pmCategoryList"
              :key="category.id"
              :label="category.name"
              class="mb-2 px-2 leading-7 hover:text-primary"
              @click="toGoods(1,category.id)"
            >
              {{category.name}}
            </li>
          </ul>
        </div>
        <div class="border-t border-dashed border-gray-300"></div>
        <div>
          <div class="flex justify-between items-baseline">
            <p class="text-lg">标的物所在地</p>
            <p class="text-xs hover:text-primary cursor-pointer" @click="toGoods">更多</p>
          </div>
          <ul class="flex flex-wrap text-sm text-gray-500 mt-4 cursor-pointer">
            <li
              v-for="region in sysRegionList"
              :key="region.code"
              class="mb-2 px-2 leading-7 hover:text-primary"
              @click="toGoods(2,region.code)"
            >
              {{region.name}}
            </li>
          </ul>
        </div>
      </div>
      <div class="flex-1 mx-2">
        <el-carousel autoplay :interval="5000" arrow="always" height="384px">
          <el-carousel-item v-for="item in banner" :key="item.id">
            <img :src="item.img" class="object-cover w-full" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="h-full w-60">
        <div class="h-20 border flex items-center pl-6">
          <div
            class="w-12 h-12 border rounded-full overflow-hidden text-center leading-12 pt-1"
          >
            <i class="el-icon-s-custom text-3xl text-gray-400" />
          </div>
          <div
            class="ml-2 text-sm text-gray-600"
            v-if="$store.state.token == ''"
          >
            <p>您好！</p>
            <p>请先登录</p>
          </div>
          <div class="ml-2 text-sm text-gray-600" v-else>
            <p>您好！{{ $store.state.user.name }}</p>
            <p>欢迎来到冀中拍卖</p>
          </div>
        </div>
        <div
          class="h-9 border border-primary w-full flex items-center text-center"
          v-if="$store.state.token == ''"
        >
          <div
            class="w-1/2 bg-primary text-white h-full leading-9 cursor-pointer"
            @click="goLogin"
          >
            登录
          </div>
          <div class="w-1/2 h-full leading-9 cursor-pointer" @click="goReg" >注册</div>
        </div>
        <div
          class="h-9 border border-primary w-full flex items-center text-center"
          v-else
        >
          <div
            class="w-full bg-primary text-white h-full leading-9 cursor-pointer"
            @click="goCenter"
          >
            个人中心
          </div>
        </div>
        <div class="h-64 border mt-2 px-4">
          <div class="flex justify-between items-center h-10 border-b">
            <p class="text-lg">
              <i class="el-icon-bell text-red-500 mr-1" />公告
            </p>
            <p class="text-xs cursor-pointer " @click="toNotice">更多</p>
          </div>
          <ul class="notices py-3">
            <li
              v-for="goodNotice in goodNoticeList"
              :key="goodNotice.id"
              class="truncate leading-8 text-xs border-b border-dashed  hover:bg-gray-100 hover:text-primary cursor-pointer"
               @click="selectNotice(1,goodNotice.id,goodNotice.goodsId)"
            >
              {{goodNotice.title}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="note bg-gray-100 h-10 flex justify-start leading-10 my-2 container mx-auto"
    >
      <div class="w-32 h-10 px-4 leading-10 text-base text-white bg-primary">
        <i class="el-icon-bell"></i>
        <span class="text-base ml-2">最新通知</span>
      </div>
      <div class="px-5 flex items-center flex-1 text-sm">
        <text-scroll :dataList="dataList" />
      </div>
    </div>
    <div
      class="flow container mx-auto h-24 border my-2 overflow-hidden p-2 flex items-center justify-between"
    >
      <div class="h-20 leading-20 w-44 border-r text-xl font-bold text-center">
        竞拍流程
      </div>
      <pm-lc class="flex-1 mx-16" />
    </div>

    <div
      class="leader container mx-auto h-14 border-primary border-b-2 flex justify-between items-center box-border"
    >
      <div
        class="w-60 bg-primary h-full border-primary text-gray-100 text-xl py-1 pl-6 leading-12 font-bold tracking-widest"
      >
        热门标的
      </div>
      <div class="text-xs pr-4 cursor-pointer" @click="toGoods">更多</div>
    </div>

    <div class="container mx-auto grid grid-cols-4 gap-5 mt-5">
      <goods-item v-for="hotGoods in hotGoodsList" :key="hotGoods.id" :hotGoods="hotGoods"  @selectOK="selectGoods(hotGoods.id)" />
    </div>
    <div
      class="leader container mx-auto h-14 border-primary border-b-2 flex justify-between items-center box-border mt-4"
    >
      <div
        class="w-60 bg-primary h-full border-primary text-gray-100 text-xl py-1 pl-6 leading-12 font-bold tracking-widest"
      >
        竞拍专场
      </div>
      <div class="text-xs pr-4 cursor-pointer" @click="toGroup">更多</div>
    </div>
    <div class="container mx-auto">
      <group-item v-for="groupObj in groupList" :key="groupObj.id" :groupObj="groupObj" @selectOK="selectGroup(groupObj.id)" />
    </div>
  </div>
</template>

<script>
import GoodsItem from "../../components/GoodsItem.vue";
import GroupItem from "../../components/GroupItem.vue";
import PmLc from "../../components/PmLc.vue";
import PmPay from "../../components/PmPay.vue";
import TextScroll from "../../components/TextScroll.vue";
export default {
  components: { GoodsItem, GroupItem, PmLc, TextScroll, PmPay },
  data() {
    return {
      websock: null,
      banner: [],
      tognzhi: [],
      dataList: [],
      pmCategoryList: [], //标的物分类
      sysRegionList: [], //标的物所在地（省级）
      goodNoticeList: [], //公告栏
      hotGoodsList: [], //热门标的物
      groupList: [], // 竞拍专场
    };
  },
  mounted() {
    this.loadBanner();
    this.loadNotices();
    this.getCategoryList(); //标的物类型
    this.getRegionList(); //标的物所在地
    this.getGoodNoticeList(); //公告栏 
    this.getGoodsList(); //热门标的
    this.getGroupList(); //首页竞拍专场
  },
  methods: {
    loadNotices() {
      this.$get("/pm/cms/notices/1").then((res) => {
        this.tognzhi = res.data;
        this.dataList = [];
        for (var i = 0; i < this.tognzhi.length; i++) {
          this.dataList.push(this.tognzhi[i].noticeTitle);
        }
      });
    },
    loadBanner() {
      this.$get("/pm/cms/links/0").then((res) => {
        this.banner = res.data;
      });
    },
    selectGoods(goodsId) {
      this.$store.commit("nav", 1);
      this.$router.push({
          path: '/goods/info', 
          query:{
            goodsId: goodsId
          }
        });
    },
    selectGroup(groupId) {
      this.$store.commit("nav", 2);
      this.$router.push({
          path: '/group/info', query:{groupId: groupId}
        });
    },
    toGroup() {
      this.$store.commit("nav", 2);
      this.$router.push("/group");
    },
    toGoods(type,param) {
      this.$store.commit("nav", 1);
      if(type == 0){ // 直接跳转
        this.$router.push("/goods");
      }else if(type == 1){ //标的物类型
        this.$router.push({
          path: '/goods', query:{category: param}
        });
      }else if(type == 2){ //标的物所在地
        this.$router.push({
          path: '/goods', query:{province: param}
        });
      }
    },
    toNotice() {
      this.$store.commit("nav", 3);
      this.$router.push("/notice");
    },
    goHome() {
      this.$store.commit("nav", 0);
      this.$router.push("/");
    },
    goLogin() {
      this.$router.push("/login");
    },
    goReg() {
      this.$router.push("/register");
    },
    goCenter() {
      this.$router.push("/ucenter");
    },
    selectNotice(type,noticeId,goodsId){
      this.$store.commit("nav", 3);
      if(type == 1){ //公告详情
        this.$router.push({
          path: '/notice/info', 
          query:{
            goodsId: goodsId
            }
        });
      }
    },
    //标的物类型列表
    getCategoryList(){
      this.$get(`/pm/auction/getPmCategoryList`, []).then(
        (res) => {
          if(res.code === 200){
            this.pmCategoryList = res.data
          }
        }
      );
    },
    //标的物所在地列表
    getRegionList(){
      let queryParams={
          pcode: '0',
          status: 1
        }
      this.$get(`/pm/auction/getSysRegionList`, queryParams).then(
        (res) => {
          if(res.code === 200){
            this.sysRegionList = res.data
          }
        }
      );
    },
    //公告栏
    getGoodNoticeList(){
      let queryParams={
          isRelease: 1, //已发布
          type: 1,
          goodsStatus: '', //商品状态集合
          topNum: 6   // 取指定条数
        }
      this.$get(`/pm/auction/getPmGoodNoticeList`, queryParams).then(
        (res) => {
          if(res.code === 200){
            this.goodNoticeList = res.rows
          }
        }
      );
    },
    //热门标的
    getGoodsList(){
      let queryParams={
          "topNum": 8,
        }
      this.$get(`/pm/auction/getGoodsList`, queryParams).then(
        (res) => {
          if(res.code === 200){
            this.hotGoodsList = res.rows
          }
        }
      );
    },
    //首页内-竞拍专场
    getGroupList(){
      let queryParams={
          topNum: 3,
        }
      this.$get(`/pm/auction/getGroupGoodList`, queryParams).then(
        (res) => {
          if(res.code === 200){
            console.log("首页内-竞拍专场");
            console.log(res);
            this.groupList = res.rows
          }
        }
      );
    },



  },
};
</script>

<style  scoped>
.notices li {
  list-style: circle;
}

.notices li:nth-child(1) {
   @apply text-primary
}
.notices li:nth-child(2) {
   @apply text-primary
}

.notices li:nth-child(6) {
  @apply border-none;
}
</style> 