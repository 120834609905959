<template>
  <div>
    <el-form
      ref="ruleForm"
      :rules="rules"
      :model="form"
      label-width="300px"
      size="medium"
      style="width: 600px"
    >
      <el-form-item label="原密码" prop="uname">
        <el-input  type="password" v-model="form.uname"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="passwd" required>
        <el-input type="password" v-model="form.passwd"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="passwd2" required>
        <el-input  type="password" v-model="form.passwd2"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >密码修改</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6 || value.length > 10) {
        callback(new Error("请输入（6-10位）密码"));
      } else {
        if (this.form.passwd2 !== "") {
          this.$refs.ruleForm.validateField("passwd2");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.passwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        uname: "",
        passwd: "",
        passwd2: "",
      },
      rules: {
        uname: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        passwd: [{ validator: validatePass, trigger: "blur" }],
        passwd2: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/pm/login/restPwd", this.form).then((res) => {
            this.$message({
                message: "密码修改成功！",
                type: "success"
              });
          });
        } else {
          return false;
        }
      });
    }
  },
};
</script>

<style>
</style>