<template>
  <div class="bg-white py-2">
    <div
      class="search container mx-auto my-2 border h-80 flex justify-between items-start px-16 pt-16"
      style="
        background-image: url(http://www.cpcep.com/uploads/allimg/151207/1-15120G35R90-L.png);
        background-size: cover;
      "
    >
      <div>
        <p class="tracking-widest font-bold text-white text-2xl">{{groupList.groupName}}</p>
        <p class="tracking-widest text-white mt-10">{{groupList.goodsinfo}}</p>
      </div>
      <div class="w-80 h-40 shadow-lg  rounded-lg">
        <div class="h-1/2 bg-primary p-3">
          <p class="tracking-widest font-bold text-white text-lg">
            开始时间 -  结束时间
          </p>
          <p class="tracking-widest font-bold text-white text-lg">
             {{groupList.beginTime | dateFormat}} - {{groupList.endTime | dateFormat}}
          </p>
        </div>
        <div class="h-1/3 bg-white  text-primary p-3">
          <p class="tracking-widest  font-bold" v-if="groupList.pmGoodsList != null">标的： {{groupList.pmGoodsList.length}}件</p>
          <p class="tracking-widest  font-bold" v-else>标的： 0件</p>
          <!-- <p class="tracking-widest  font-bold">围观： 2707</p> -->
        </div>
      </div>
    </div>
    <div
      class="sort container mx-auto my-2 border h-10 flex items-center text-gray-500"
    >
      <!-- <div class="">默认</div>
      <div class="border-l border-r active1">
        报价次数 <i class="el-icon-bottom" />
      </div>
      <div class="border-l border-r">
        限时报价开始时间 <i class="el-icon-bottom" />
      </div>
      <div class="border-r">报价次数 <i class="el-icon-top" /></div> -->
      <div :class=" queryParams.pricecountOrderBy == null && queryParams.pmksTimeOrderBy  == null ? 'active1' : ''" @click="setOrderByParam(null)">默认</div>
      <div :class=" queryParams.pricecountOrderBy != null ? 'border-l border-r active1' : 'border-l border-r'" @click="setPricecountOrderByParam()">
        报价次数 <i :class=" queryParams.pricecountOrderBy == 1 ? 'el-icon-top' : 'el-icon-bottom'" />
      </div>
      <div :class=" queryParams.pmksTimeOrderBy != null ? 'border-l border-r active1' : 'border-l border-r'" @click="setPmksTimeOrderByParam()">
        限时报价开始时间 <i :class=" queryParams.pmksTimeOrderBy == 2 ? 'el-icon-top' : 'el-icon-bottom'" />
      </div>
    </div>
    <div class="container mx-auto data my-2 grid grid-cols-4 gap-5">
      <!-- <goods-item v-for="i in 16" :key="i" /> -->
      <goods-item v-for="hotGoods in groupList.pmGoodsList" :key="hotGoods.id" :hotGoods="hotGoods"  @selectOK="selectGoods(hotGoods.id)" />
    </div>
    <div class="pages container mx-auto text-center mt-4 my-2">
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import GoodsItem from "../../components/GoodsItem.vue";

export default {
  components: { GoodsItem },
  data() {
        return {
            groupList: [],
            queryParams: {
              groupsId: 0,
              pricecountOrderBy: null,
              pmksTimeOrderBy: null
            }
        }
    },
  mounted() {
    this.queryParams.groupsId = this.$route.query.groupId != null ? this.$route.query.groupId : 0;
    this.getGroupList(); //竞拍专场
  },
   methods:{
     selectGoods(goodsId) {
      this.$store.commit("nav", 1);
       this.$router.push({
          path: '/goods/info', 
          query:{
            goodsId: goodsId
          }
        });
    },
    //专场详情页
    getGroupList(){
      this.$get(`/pm/auction/getPmGroupInfo`, this.queryParams).then(
        (res) => {
          console.log("专场详情页");
            console.log(res);
          if(res.code === 200){
            this.groupList = res.data
          }
        }
      );
    },
    // 出价次数排序条件
    setPricecountOrderByParam(){
      this.queryParams.pricecountOrderBy = 
        this.queryParams.pricecountOrderBy == null ? 1 
          : this.queryParams.pricecountOrderBy == 2 ? 1 : 2;
      this.getGroupList();
    },
    //拍卖开始时间
    setPmksTimeOrderByParam(){
      this.queryParams.pmksTimeOrderBy = 
      this.queryParams.pmksTimeOrderBy == null ? 1 
          : this.queryParams.pmksTimeOrderBy == 2 ? 1 : 2;
      this.getGroupList();
    },
    // 恢复默认排序规则
    setOrderByParam(){
      this.queryParams.pricecountOrderBy =  null;
      this.queryParams.pmksTimeOrderBy = null;
      this.getGroupList();
    }



   }
};
</script>

<style scoped>
.active1 {
  @apply bg-primary text-white;
}

.sort div {
  @apply h-full px-5 leading-10 tracking-widest text-sm;
}
</style>