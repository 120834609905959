import axios from 'axios';
import store from '@/store'
import { Notification, MessageBox, Message } from 'element-ui'
import errorCode from '@/utils/errorCode'

axios.defaults.timeout = 5000;
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

//http request 拦截器
axios.interceptors.request.use(
    config => {
        const token = store.state.token;
        config.headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'x-requested-with,content-type,Authorization',
            'token': 'Token ' + token
        }
        if (config.url.indexOf("/upload") != -1) {
            config.headers['Content-Type'] = 'multipart/form-data'
        } else {
            config.data = JSON.stringify(config.data);
            config.headers['Content-Type'] = 'application/json;charset=utf-8'
        }
        return config;
    },
    error => {
        return Promise.reject(err);
    }
);


// 响应拦截器
axios.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        //return res;
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode['default']
        if (code === 401) {
            Message({
                message: "登录状态已过期，重新登录",
                type: 'warning',
                duration: 3 * 1000
            })
            store.commit("setToken", "");
            store.commit("setUser", {});
            location.href = '/#/login';
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error)
        let { message } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        // Message({
        //     message: message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
    }
)



/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, config)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}