<template>
  <div class="bg-white py-2">
    <div class="search container mx-auto my-2 border">
      <div>
        <p class="title">处置方式：</p>
        <ul>
          <li :class=" goodsPraams.pmtype == null? 'active1' : 'active2'" @click="setPmtypeParam(null)">全部</li>
          <li @click="setPmtypeParam(1)" :class=" goodsPraams.pmtype === 1 ? 'hover:text-primary active1':'hover:text-primary'">自由报价</li>
          <li @click="setPmtypeParam(2)" :class=" goodsPraams.pmtype === 2 ? 'hover:text-primary active1':'hover:text-primary'">竞拍报价</li>
        </ul>
      </div>
      <div>
        <p class="title">项目类别：</p>
        <ul>
          <li :class=" goodsPraams.category == null? 'active1' : 'active2'" @click="setCategoryParam(null)">全部</li>
          <div v-for="category in pmCategoryList"
              :key="category.id">
            <li 
              @click="setCategoryParam(category.id)"
               :class="goodsPraams.category == category.id ? 'mb-1 px-2 leading-7 hover:text-primary active1': 'mb-1 px-2 leading-7 hover:text-primary'">
              {{category.name}}
            </li>
          </div>
        </ul>
      </div>
      <div>
        <p class="title">所在地区：</p>
        <ul>
          <li :class=" goodsPraams.province == null? 'active1' : 'active2'" @click="setProvinceParam(null)">全部</li>
          <li
              v-for="region in sysRegionList"
              :key="region.code"
              :label="region.name"
               :class=" goodsPraams.province == region.code ? 'hover:text-primary active1' : 'hover:text-primary'"
               @click="setProvinceParam(region.code)"
            >
            {{region.name}}
            </li>
        </ul>
      </div>
      <!-- <div>
        <p class="title">起始价：</p>
        <ul>
          <li :class=" goodsPraams.minPriceRange == null &&  goodsPraams.maxPriceRange == null ? 'active1' : 'active2'" @click="setpriceRangeParam(null,null)">全部</li>
          <li :class=" goodsPraams.minPriceRange === 0 && goodsPraams.maxPriceRange === 5000 ? 'hover:text-primary active1' : 'hover:text-primary'"
          @click="setpriceRangeParam(0,5000)" 
          >￥0-5千</li>
          <li :class=" goodsPraams.minPriceRange === 5000 && goodsPraams.maxPriceRange === 10000 ? 'hover:text-primary active1' : 'hover:text-primary'"
          @click="setpriceRangeParam(5000,10000)" 
          >￥5千 - 1万</li>
          <li :class=" goodsPraams.minPriceRange === 10000 && goodsPraams.maxPriceRange === 30000 ? 'hover:text-primary active1' : 'hover:text-primary'"
          @click="setpriceRangeParam(10000,30000)" 
          >￥1万 - 3万</li>
          <li :class=" goodsPraams.minPriceRange === 30000 && goodsPraams.maxPriceRange === 50000 ? 'hover:text-primary active1' : 'hover:text-primary'"
          @click="setpriceRangeParam(30000,50000)" 
          >￥3万 - 5万</li>
          <li :class=" goodsPraams.minPriceRange === 50000 && goodsPraams.maxPriceRange === null ? 'hover:text-primary active1' : 'hover:text-primary'"
          @click="setpriceRangeParam(50000,null)" 
          >￥5万以上</li>
        </ul>
      </div> -->
      <div>
        <p class="title">竞拍状态：</p>
        <ul>
          <li :class=" goodsPraams.status == null  ? 'active1' : 'active2'" @click="setStatusParam(null)">全部</li>
          <li :class=" goodsPraams.status === 2 ? 'hover:text-primary active1' : 'hover:text-primary'" @click="setStatusParam(2)">即将竞拍</li>
          <li :class=" goodsPraams.status === 3 ? 'hover:text-primary active1' : 'hover:text-primary'" @click="setStatusParam(3)">竞价中</li>
          <li :class=" goodsPraams.status === 4 ? 'hover:text-primary active1' : 'hover:text-primary'" @click="setStatusParam(4)">已结束</li>
        </ul>
      </div>
    </div>
    <div
      class="sort container mx-auto my-2 border h-10 flex items-center text-gray-500"
    >
      <div :class=" goodsPraams.pricecountOrderBy == null && goodsPraams.pmksTimeOrderBy  == null ? 'active1' : ''" @click="setOrderByParam(null)">默认</div>
      <div :class=" goodsPraams.pricecountOrderBy != null ? 'border-l border-r active1' : 'border-l border-r'" @click="setPricecountOrderByParam()">
        报价次数 <i :class=" goodsPraams.pricecountOrderBy == 1 ? 'el-icon-top' : 'el-icon-bottom'" />
      </div>
      <div :class=" goodsPraams.pmksTimeOrderBy != null ? 'border-l border-r active1' : 'border-l border-r'" @click="setPmksTimeOrderByParam()">
        限时报价开始时间 <i :class=" goodsPraams.pmksTimeOrderBy == 2 ? 'el-icon-top' : 'el-icon-bottom'" />
      </div>
    </div>
    <div class="container mx-auto data my-2 grid grid-cols-4 gap-5">
      <goods-item v-for="hotGoods in auctionGoodsList" :key="hotGoods.id" :hotGoods="hotGoods"  @selectOK="selectGoods(hotGoods.id)" />
    </div>
    <div class="pages container mx-auto text-center mt-4 my-2">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="goodsPraams.pageSize"
        :total="total"
        @current-change="pageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import GoodsItem from "../../components/GoodsItem.vue";

export default {
  components: { GoodsItem },
  data() {
    return {
      auctionGoodsList: [], //商品列表
      pmCategoryList: [], //商品分类
      sysRegionList: [], // 所在地区
      queryCategory: [], //项目类别条件集合
      goodsPraams: {  // 竞拍页查询条件
        pmtype: null, //处置方式
        category: null, //项目类别
        province: null, //所在地
        minPriceRange: null, //最小起始价
        maxPriceRange: null, //最大起始价
        status: null, //竞拍状态
        pricecountOrderBy: null, //出价次数排序规则 1:正序  2:倒序
        pmksTimeOrderBy: null, //拍卖开始时间排序规则 1:正序  2:倒序
        pageNum: 1,
        pageSize: 16,
        qjKey:"",//全局搜索条件
      }, 
      total:0,
    };
  },
  created(){
    this.goodsPraams.qjKey = this.$store.state.qjKey;
  },
  computed:{
    listenQjKey(){
      return this.$store.state.qjKey
      }
   },
   watch: {
    listenQjKey: function(val, oldval){
      if (val != oldval) {
         this.goodsPraams.qjKey = this.$store.state.qjKey;
         this.getGoodsList();
      }
    }
  },
  mounted() {
    this.goodsPraams.category = this.$route.query.category != null ? this.$route.query.category : null
    this.goodsPraams.province = this.$route.query.province != null ? this.$route.query.province : null
    this.getGoodsList(); //竞拍页数据
    this.getCategoryList(); //商品分类
    this.getRegionList(); //地区
  },
   methods:{
    selectGoods(goodsId) {
      this.$store.commit("nav", 1);
       this.$router.push({
          path: '/goods/info', 
          query:{
            goodsId: goodsId
          }
        });
    },
     //竞拍页商品列表
    getGoodsList(){
      this.$get(`/pm/auction/getGoodsList`, this.goodsPraams).then(
        (res) => {
          if(res.code === 200){
            console.log("竞拍页");
            console.log(res);
            this.auctionGoodsList = res.rows
            this.total = res.total;
          }
        }
      );
    },
    pageChange(n) {
      this.goodsPraams.pageNum = n;
      this.getGoodsList();
    },
    //标的物类型列表
    getCategoryList(){
      this.$get(`/pm/auction/getPmCategoryList`, []).then(
        (res) => {
          if(res.code === 200){
            this.pmCategoryList = res.data
            for(var i=0;i< this.pmCategoryList.length;i++){
                this.pmCategoryList[i].queryCheck=false
            }
          }
        }
      );
    },
     //标的物所在地区列表
    getRegionList(){
      let queryParams={
          pcode: '0',
          status: 1
        }
      this.$get(`/pm/auction/getSysRegionList`, queryParams).then(
        (res) => {
          if(res.code === 200){
            this.sysRegionList = res.data
          }
        }
      );
    },
    //处置方式条件
    setPmtypeParam(param){ 
      this.goodsPraams.pmtype =  this.goodsPraams.pmtype == param ? null : param;
      this.getGoodsList();
    },
    // 项目类别条件
    setCategoryParam(param){
      this.goodsPraams.category = this.goodsPraams.category == param ? null : param;
      this.getGoodsList();
    },
     // 所在地区条件
    setProvinceParam(param){
      this.goodsPraams.province = this.goodsPraams.province == param ? null : param;
      this.getGoodsList();
    },
    // 起始价条件
    setpriceRangeParam(min,max){
      this.goodsPraams.minPriceRange = this.goodsPraams.minPriceRange == min ? null : min;
      this.goodsPraams.maxPriceRange = this.goodsPraams.maxPriceRange == max ? null : max;
      this.getGoodsList();
    },
    // 竞拍状态条件
    setStatusParam(param){
      this.goodsPraams.status = this.goodsPraams.status == param ? null : param;
      this.getGoodsList();
    },
    // 出价次数排序条件
    setPricecountOrderByParam(){
      this.goodsPraams.pricecountOrderBy = 
        this.goodsPraams.pricecountOrderBy == null ? 1 
          : this.goodsPraams.pricecountOrderBy == 2 ? 1 : 2;
      this.getGoodsList();
    },
    //拍卖开始时间
    setPmksTimeOrderByParam(){
      this.goodsPraams.pmksTimeOrderBy = 
      this.goodsPraams.pmksTimeOrderBy == null ? 1 
          : this.goodsPraams.pmksTimeOrderBy == 2 ? 1 : 2;
      this.getGoodsList();
    },
    // 恢复默认排序规则
    setOrderByParam(){
      this.goodsPraams.pricecountOrderBy =  null;
      this.goodsPraams.pmksTimeOrderBy = null;
      this.getGoodsList();
    }




   }
};
</script>

<style scoped>

.active2 {
  @apply text-primary  font-semibold;
}

.active1 {
  @apply bg-primary text-white;
}

.sort div {
  @apply h-full px-5 leading-10 tracking-widest text-sm;
}

.search div {
  @apply h-10 leading-10 border-b border-dashed flex items-center text-gray-500 text-sm;
}

.search  .title {
    @apply w-24 text-center font-semibold bg-gray-100;
}

.search div:nth-child(5) {
  @apply h-10 leading-10 border-none;
}

.search  ul {
     @apply  flex items-center ml-5;
}

.search li {
    @apply  h-6 leading-6 px-4 cursor-pointer;
}
</style>