<template>
  <div>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="拍品"></el-table-column>
        <el-table-column prop="ownerName" label="产权公司"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column prop="bmprice" label="保证金"></el-table-column>
        <el-table-column prop="pmksTime" label="拍卖时间" width="180px;">
          <template slot-scope="scope">
            {{
            scope.row.pmksTime | dateTimeFormat
            }} - {{
            scope.row.pmjs2Time | dateTimeFormat
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-view"
              @click="handleUpdate(scope.row.id)"
            >出价记录</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pages container mx-auto text-center mt-4 my-2">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="queryParams.pageSize"
        :total="total"
        @current-change="pageChange"
      ></el-pagination>
    </div>
    <el-dialog title="出价记录" :visible.sync="open" width="700px" append-to-body>
      <el-table :data="priceList" style="width: 100%">
        <el-table-column prop="enrollCode" label="竞拍号"></el-table-column>
        <el-table-column prop="money" label="价格"></el-table-column>
        <el-table-column prop="createTime" label="出价时间">
          <template slot-scope="scope">
            {{
            scope.row.createTime | dateTimeFormat
            }}
          </template>
        </el-table-column>
        <el-table-column prop="top" label="是否最高价">
          <template slot-scope="scope">
            <span v-if="scope.row.top==1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
      </el-table>
    <div class="pages container mx-auto text-center mt-4 my-2">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="queryParams1.pageSize"
        :total="total1"
        @current-change="pageChange1"
      ></el-pagination>
    </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      total1: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 5
      },
      queryParams1: {
        pageNum: 1,
        pageSize: 5,
        goodsId: undefined,
      },
      tableData: [],
      priceList:[],
      open:false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableData = [];
      this.$get("/pm/ucenter/listPrice", this.queryParams).then(res => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    pageChange(n) {
      this.queryParams.pageNum = n;
      this.getList();
    },
     pageChange1(n) {
      this.queryParams1.pageNum = n;
      this.getPriceList();
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.getList();
    },
     getPriceList() {
      this.priceList = [];
      this.$get("/pm/ucenter/listMyPrice", this.queryParams1).then(res => {
        this.priceList = res.rows;
        this.total1 = res.total;
      });
    },
    /** 出价记录 */
    handleUpdate(id) {
      this.queryParams1.goodsId = id;
      this.getPriceList();
      this.open=true;
    }
  }
};
</script>

<style>
</style>