<template>
  <div class="footer w-full  bg-gray-50">
      <div class=" h-28 mx-auto container border-b px-10 overflow-hidden" style="display:none">
        <div class=" h-full w-full flex items-center justify-around">
            <div  class=" flex items-center justify-center">
                <img src="@/assets/images/foot-1.png" class="h-16 w-16" />
                <div class="ml-2">
                  <p class=" font-bold">帮助中心</p>
                  <p class=" text-gray-500 text-sm mt-1">悉心指导、操作无忧</p>
                </div>
            </div>
             <div  class=" flex items-center justify-center">
                <img src="@/assets/images/foot-2.png" class="h-16 w-16" />
                <div class="ml-2">
                  <p class=" font-bold">特色服务</p>
                  <p class=" text-gray-500 text-sm mt-1">快速响应、定制需求</p>
                </div>
            </div>
            <div  class=" flex items-center justify-center">
                <img src="@/assets/images/foot-3.png" class="h-16 w-16" />
                <div class="ml-2">
                  <p class=" font-bold">售后无忧</p>
                  <p class=" text-gray-500 text-sm mt-1">退换无忧、维修无忧</p>
                </div>
            </div>
            <div  class=" flex items-center justify-center">
                <img src="@/assets/images/foot-4.png" class="h-16 w-16" />
                <div class="ml-2">
                  <p class=" font-bold">极速物流</p>
                  <p class=" text-gray-500 text-sm mt-1">一站服务、快速送达</p>
                </div>
            </div>
            <div  class=" flex items-center justify-center">
                <img src="@/assets/images/foot-5.png" class="h-16 w-16" />
                <div class="ml-2">
                  <p class=" font-bold">正品保证</p>
                  <p class=" text-gray-500 text-sm mt-1">正品行货、品类齐全</p>
                </div>
            </div>
        </div>
      </div>
      <div class=" h-20 bg-gray-600 text-gray-400 text-center text-xs pt-2 leading-7">
        <p>Copyright ©2016 集中能源集团 All rights reserved.  |  最终解释权：沪ICP备202100964 上海勤璞科技有限公司 </p>
        <p>平台收费标准 | 价格举报电话：12358</p>
      </div>
      <div class=" h-8 leading-8 text-center text-gray-600  text-xs">
        为获得最佳浏览器体验，本平台推荐使用Chrome、火狐、IE9以上浏览器
      </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>