<template>
  <div>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="拍品"></el-table-column>
        <el-table-column prop="ownerName" label="产权公司"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column prop="bmprice" label="保证金"></el-table-column>
        <el-table-column prop="pmksTime" label="拍卖时间" width="180px;">
          <template slot-scope="scope">
            {{
            scope.row.pmksTime | dateTimeFormat
            }} - {{
            scope.row.pmjs2Time | dateTimeFormat
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row.id)"
            >取消关注</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-user"
              v-if="!scope.row.enrollId"
              @click="myenroll(scope.row.id)"
            >报名</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-upload2"
              v-if="scope.row.enrollId"
              @click="zzUpload(scope.row.enrollId)"
            >资质上传</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pages container mx-auto text-center mt-4 my-2">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="queryParams.pageSize"
        :total="total"
        @current-change="pageChange"
      ></el-pagination>
    </div>
    <el-dialog title="资质上传" :visible.sync="viewVisible" width="700px" append-to-body>
      <el-upload
        class="upload-demo"
        :action="url"
        :on-preview="handlePreview"
        :before-remove="beforeRemove"
        multiple
        :limit="1"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :http-request="httpRequest"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="fileUpload">确 认</el-button>
        <el-button @click="cancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "1",
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 5
      },
      tableData: [],
      form: {
        goodsId: undefined,
        type: 0
      },
      queryParam: {
        goodsId: 0,
        id: "",
        type: 1,
        agency: "",
        money: 0
      },
      viewVisible: false,
      url: "",
      fileList: [],
      fileForm: {
        id: undefined,
        attachment: ""
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableData = [];
      this.$get("/pm/ucenter/listFollows", this.queryParams).then(res => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    pageChange(n) {
      this.queryParams.pageNum = n;
      this.getList();
    },
    /** 取消关注 */
    handleUpdate(id) {
      this.form.goodsId = id;
      var _this = this;
      this.$confirm("是否取消对该拍品的关注?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        _this.$post("/pm/ucenter/follow", _this.form).then(res => {
          _this.getList();
        });
      });
    },
    myenroll(id) {
      this.queryParam.id = id;
      this.queryParam.goodsId = id;
      this.$get("/pm/ucenter/enroll", this.queryParam).then(res => {
        if (res.code == 200) {
          this.$message({
            message: "报名成功",
            type: "success"
          });
          this.getList();
        }
      });
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest(params) {
      let form = new FormData();
      form.append("file", params.file);
      this.$post("/pm/ucenter/upload", form).then(res => {
        this.fileForm.attachment = res.url;
      });
    },
    zzUpload(id) {
      this.viewVisible = true;
      this.fileForm.id = id;
    },
    fileUpload() {
      if (this.fileForm.attachment) {
        this.$get("/pm/ucenter/zzUpload", this.fileForm).then(res => {
          if (res.code == 200) {
            this.$message({
              message: "上传成功",
              type: "success"
            });
            this.viewVisible = false;
          }
        });
      } else {
        this.$message({
          message: "请上传文件",
          type: "warning"
        });
      }
    },
    cancel() {
      this.fileForm.id = undefined;
      this.fileForm.attachment = undefined;
      this.viewVisible = false;
    }
  }
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 160px;
  height: 200px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 120px;
  display: block;
}
</style>