<template>
  <div>
    <div class="h-10 bg-gray-100 flex justify-between">
      <div
        class="
          container
          mx-auto
          flex
          justify-between
          text-xs text-gray-400
          items-center
        "
      >
        <p>你好，欢迎来到冀中拍卖</p>
        <ul class="flex items-center">
          <li>客服电话：400-800-1234</li>
          <li class="ml-10" @click="goHome">首页</li>
        </ul>
      </div>
    </div>
    <div
      class="container mx-auto h-28 flex items-center justify-between text-sm"
    >
      <img src="@/assets/logo.png" class="h-10" />
      <div>
        已有账号？
        <span class="text-blue-400" @click="goLogin"> 立即登陆 </span>
      </div>
    </div>
    <div class="border-b"></div>
    <div class="bg-gray-100 pb-10">
      <div class="container mx-auto bg-white py-5 px-28">
        <el-form
          ref="ruleForm"
          :rules="rules"
          :model="form"
          label-width="300px"
          size="medium"
          style="width: 600px"
        >
          <div class="font-bold border-b pb-2 mb-5">
            账号信息
            <span class="ml-5 text-xs text-gray-400"
              >请填写以下注册信息，<span class="text-red-500">*</span> 为必填项
            </span>
            <span class="ml-5 text-xs text-red-400"
              >(一旦注册企业名称和用户名无法修改，请确认无误后注册)
            </span>
          </div>
          <el-form-item label="我是：">
            <el-radio-group v-model="form.type">
              <el-radio label="0">竞买人</el-radio>
              <el-radio label="1">竞价代理机构</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="企业名称：" prop="comname">
            <el-input v-model="form.comname"></el-input>
          </el-form-item>
          <el-form-item label="用户名：" prop="uname">
            <el-input v-model="form.uname"></el-input> <span></span>
          </el-form-item>
          <el-form-item label="密码：" prop="passwd" required>
            <el-input v-model="form.passwd" type="password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="passwd2" required>
            <el-input v-model="form.passwd2" type="password"></el-input>
          </el-form-item>
          <div class="font-bold border-b pb-2 mb-5">联系方式</div>
          <el-form-item label="联系人：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="联系电话:" prop="mobile">
            <el-input v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="手机验证码:" prop="mobileCode">
            <el-input v-model="form.mobileCode" style="width: 170px"></el-input>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <el-button
              type="primary"
              v-show="isShow"
              @click="getCode"
              style="width: 110px"
              >获取验证码</el-button
            >
            <el-button
              disabled="false"
              v-show="!isShow"
              style="width: 110px; background-color: lightgrey; font-size: 14px"
              >{{ secondsCount }}秒后重发</el-button
            >
          </el-form-item>
          <el-form-item label="邮箱：" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="code">
            <div class="flex justify-between items-center">
              <el-input
                v-model="form.code"
                auto-complete="off"
                placeholder="验证码"
                class="w-24"
              >
              </el-input>
              <img :src="codeUrl" @click="getYZCode" class="h-9 w-24" />
            </div>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="checked"
              >已经<span @click="dialogTableVisible = true"
                >阅读相关协议</span
              ></el-checkbox
            >
          </el-form-item>
          <el-form-item label="">
            <el-button
              type="primary"
              style="width: 300px; height: 40px"
              :disabled="!checked"
              @click="submitForm('ruleForm')"
              >立即注册</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="container mx-auto text-xs text-gray-400 text-center my-5">
        © 2018- 2021 上海勤璞科技有限公司
      </div>
    </div>
    <el-dialog title="协议" :visible.sync="dialogTableVisible">
      协议内容
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var checkphone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
        if (!phoneReg.test(value)) {
          // 引入methods中封装的检查手机格式的方法
          return callback(new Error("请输入正确的手机号!"));
        } else {
          callback();
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6 || value.length > 10) {
        callback(new Error("请输入（6-10位）密码"));
      } else {
        if (this.form.passwd2 !== "") {
          this.$refs.ruleForm.validateField("passwd2");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.passwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      checked: false,
      dialogTableVisible: false,
      phoneCode: "",
      secondsCount: 0,
      isShow: true,
      phone: "",
      codeUrl: "",
      form: {
        type: "0",
        name: "",
        uname: "",
        mobile: "",
        email: "",
        passwd: "",
        passwd2: "",
        comname: "",
        mobileCode: "",
        code: "",
        uuid: "",
      },
      rules: {
        comname: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          {
            min: 3,
            max: 30,
            message: "长度在 3 到 30 个字符",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        uname: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        passwd: [{ validator: validatePass, trigger: "blur" }],
        passwd2: [{ validator: validatePass2, trigger: "blur" }],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            validator: checkphone,
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        mobileCode: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        code: [
          { required: true, trigger: "change", message: "验证码不能为空" },
        ],
      },
    };
  },
  mounted() {
    this.getYZCode();
  },
  methods: {
    getYZCode() {
      this.$get("/captchaImage").then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.form.uuid = res.uuid;
      });
    },
    getCode() {
      var codeParams = { mobile: this.form.mobile };
      this.phoneCode = "";
      this.phone = this.form.mobile;
      if (this.form.mobile === "") {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
        return false;
      }
      const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
      if (!reg.test(this.form.mobile)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "error",
        });
        return false;
      }
      //验证手机号是否注册
      this.$post("/pm/login/checkMobile", codeParams).then((res) => {
        console.log(res);
        if (res && res.code == 200) {
          this.$post("/pm/login/sendCode", codeParams).then((res) => {
            if (res && res.code == 200) {
              this.phoneCode = res.msg;
            }
          });
          this.secondsCount = 60;
          // 切换倒计时按钮
          this.isShow = false;
          let _this = this;
          // 倒计时函数
          let timer = setInterval(function () {
            _this.secondsCount--;
            if (_this.secondsCount <= 0) {
              clearInterval(timer);
              _this.isShow = true;
            }
          }, 1000);
        } else {
          this.$message({
            message: "该手机号已注册",
            type: "error",
          });
        }
      });
    },
    onSubmit() {
      console.log("submit!");
    },
    goHome() {
      this.$store.commit("nav", 0);
      this.$router.push("/");
    },
    goLogin() {
      this.$router.push("/login");
    },
    goReg() {
      this.$router.push("/register");
    },
    isCellPhone(val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false;
      } else {
        return true;
      }
    },
    submitForm(formName) {
      if (this.form.mobileCode != this.phoneCode) {
        this.$message({
          message: "验证码不正确",
          type: "error",
        });
        return false;
      } else {
        if (this.form.mobile !== this.phone) {
          this.$message({
            message: "手机号变更请重新验证",
            type: "error",
          });
          return false;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/pm/login/reg", this.form)
            .then((res) => {
              if (res.code == 200) {
                if ("1" == this.form.type) {
                  this.$alert("注册成功！请等待管理员审核！", "系统提示", {
                    confirmButtonText: "确定",
                    callback: (action) => {
                      this.goHome();
                    },
                  });
                } else {
                  this.$alert("注册成功！请登陆！", "系统提示", {
                    confirmButtonText: "确定",
                    callback: (action) => {
                      this.goLogin();
                    },
                  });
                }
              } else {
                this.getYZCode();
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            })
            .catch(() => {
              this.getYZCode();
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
.title {
  @apply text-right w-1/3;
}

.mytext {
  @apply w-2/3 text-left  my-3 font-thin;
}
.myinput {
  @apply text-left outline-none ml-5 border;
}
</style>