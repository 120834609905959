<template>
  <div class="bg-white py-4">
    <div class="container mx-auto flex border ">
      <center-menu ></center-menu>
      <div class="ml-5 p-5" style="width:1000px">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import CenterMenu from "../../components/CenterMenu.vue";
export default {
  data() {
    return {
   
    };
  },
  created(){
 this.$store.commit("nav", 0);
  },
  components: { CenterMenu },
};
</script>

<style scoped>
.list li {
  @apply h-10 leading-10 text-gray-400 flex justify-between  border-b border-dashed text-sm px-3 cursor-pointer;
}
</style>>