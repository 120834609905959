<template>
  <div class="bg-white py-4" @click="selectOK">
    <div class="container mx-auto flex border">
      <!-- <help-menu :menus="menus" :index="index" @selectOK="backList"></help-menu> -->
      <div class="flex-1">
        <div class="p-5">
          <!-- <div class="tracking-widest font-bold p-2 border-b">{{goodsNoticeInfo.title}}</div> -->
          <div class="p-4 text-center">
            <p class="title text-lg">{{goodsNoticeInfo.title}}</p>
            <p class="title text-gray-400 text-sm">时间：{{goodsNoticeInfo.ggksTime |dateFormat}} - {{goodsNoticeInfo.ggjsTime |dateFormat}}</p>
            <div class="border-b border-dashed mt-2"></div>
            <div class="text-sm text-gray-400 text-left pt-5" v-html="goodsNoticeInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelpMenu from "../../components/HelpMenu.vue";
export default {
  data() {
    return {
      index: 1,
      name: 'sss',
      params:{
        goodsId: null,
        type:1
      },
      goodsNoticeInfo: {},
    };
  },
  created() {
    this.params.goodsId = this.$route.query.goodsId != null ? this.$route.query.goodsId : null;
    this.loadNotice();
  },
  methods: {
    selectOK(){
       this.$emit("selectOK","");
     },
    loadNotice() {
       this.$get(`/pm/auction/getPmGoodNoticeInfo`, this.params).then(
        (res) => {
          if (res.code == 200) {
            this.goodsNoticeInfo = res.data;
          }
        }
      );
    },
  },
  components: { HelpMenu },
};
</script>

<style scoped>
</style>>