<template>
  <div class="header bg-white">
    <div class="top1 h-10 bg-gray-100 border-b border-gray-300">
      <div class="container mx-auto flex items-center justify-between text-gray-500 text-xs">
        <ul class="h-10 flex items-center" v-if="$store.state.token == ''">
          <li class="text-primary cursor-pointer" @click="goLogin">请登录</li>
          <li class="ml-5 cursor-pointer" @click="goReg">注册</li>
        </ul>
        <ul class="h-10 flex items-center" v-else>
          <li class="text-primary">欢迎来到冀中能源集团电子拍卖管理平台，{{ $store.state.user.name }}</li>
          <li class="ml-5" @click="logout">退出</li>
        </ul>
        <ul class="nav1 h-10 flex items-center cursor-pointer">
          <!-- <li class="border-r" @click="goHome">商城首页</li> -->
          <li class="border-r" @click="goCenter" v-if="$store.state.token != ''">个人中心</li>
          <li>
            客服电话
            <span class="text-primary">400-410-4000</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="top2 container mx-auto flex items-center h-24">
      <img src="@/assets/logo.png" class="h-14" />
      <div class="flex flex-1 justify-center">
        <div class="h-10 w-80 border-2 border-primary">
          <input
            v-model="searchKey"
            class="outline-none border-0 h-full w-72 mx-4"
            @keyup.enter="qjsearch()"
          />
        </div>
        <div
          class="h-10 w-20 border border-3 border-primary bg-primary leading-10 text-center text-white"
          @click="qjsearch"
        >
          <i class="el-icon-search" /> 搜索
        </div>
      </div>
    </div>
    <ul
      class="m-auto container flex justify-start items-center h-10 nav2 border-b-2 border-primary text-primary cursor-pointer font-semibold tracking-widest"
    >
      <li :class="$store.state.navIndex == 0 ? 'active' : ''" @click="gotoPage(0, '/')">首页</li>
      <li :class="$store.state.navIndex == 1 ? 'active' : ''" @click="gotoPage(1, '/goods')">竞拍</li>
      <li :class="$store.state.navIndex == 2 ? 'active' : ''" @click="gotoPage(2, '/group')">专场</li>
      <li :class="$store.state.navIndex == 3 ? 'active' : ''" @click="gotoPage(3, '/notice')">公告</li>
      <li :class="$store.state.navIndex == 4 ? 'active' : ''" @click="gotoPage(4, '/notice2')">公示</li>
      <li :class="$store.state.navIndex == 5 ? 'active' : ''" @click="gotoPage(5, '/help')">帮助</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchKey: ""
    };
  },
  props: ["index"],
  methods: {
    gotoPage(index, url) {
      this.$store.commit("nav", index);
      this.$router.push(url);
    },
    goCenter() {
      this.$router.push("/ucenter");
    },
    goHome() {
      this.$store.commit("nav", 0);
      this.$router.push("/");
    },
    goLogin() {
      this.$router.push("/login");
    },
    goReg() {
      this.$router.push("/register");
    },
    logout() {
      this.$get("/pm/login/logout").then(res => {
        this.$store.commit("setToken", "");
        this.$store.commit("setUser", {});
        this.$store.commit("nav", 0);
        this.$router.replace("/");
      });
    },
    qjsearch() {
      this.$store.state.qjKey = this.searchKey;
      this.$store.commit("nav", 1);
      this.$router.push({
        path: "/goods"
      });
    }
  }
};
</script>

<style>
.nav1 li {
  @apply my-1 px-3;
}

.nav2 li {
  @apply h-10 leading-10 px-4  mr-4;
}

.active {
  @apply bg-primary text-white rounded-sm;
}
</style>