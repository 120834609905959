<template>
  <div class="bg-white py-2">
    <!-- <div class=" container mx-auto search mt-2 h-20 border p-5 flex items-center justify-start">
            <p class=" tracking-widest font-semibold">搜索</p>
            <div class=" h-10 leading-10 w-96  rounded-md border ml-5 text-gray-300  overflow-hidden">
                <input class=" w-80 border-none outline-none mx-3" /> <i class="el-icon-search text-gray-500" /> 
            </div>
    </div>-->
    <div class="container mx-auto">
      <!-- <group-item v-for="i in 5 " :key="i" /> -->
      <group-item
        v-for="groupObj in groupList"
        :key="groupObj.id"
        :groupObj="groupObj"
        @selectOK="selectGroup(groupObj.id)"
      />
    </div>
    <div class="pages container mx-auto text-center mt-4 my-2">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="queryParams.pageSize"
        :total="total"
        @current-change="pageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import GroupItem from "../../components/GroupItem.vue";

export default {
  components: { GroupItem },
  data() {
    return {
      groupList: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 4,
      },
    };
  },
  mounted() {
    this.getGroupList(); //竞拍专场
  },
  methods: {
    selectGroup(groupId) {
      this.$store.commit("nav", 2);
      this.$router.push({
        path: "/group/info",
        query: { groupId: groupId }
      });
    },
    //首页内-竞拍专场
    getGroupList() {
      this.$get(`/pm/auction/getGroupGoodList`, this.queryParams).then(res => {
        if (res.code === 200) {
          console.log("竞拍专场");
          console.log(res);
          this.groupList = res.rows;
          this.total=res.total;
        }
      });
    },
    pageChange(n) {
      this.queryParams.pageNum = n;
      this.getGroupList();
    },
  }
};
</script>

<style  scoped>
</style>