<template>
  <div class="text-left">
    <el-form ref="form" :model="form" label-width="150px" size="medium" style="width: 600px" inline>
      <el-card shadow="never" class="company-card">
        <div slot="header" class="clearfix">
          <span>企业信息</span>
          <span
            v-if="form.auditStatus==1"
            style="float: right; padding: 3px 0;width:150px;color:green;"
          >已审核通过</span>
          <span v-else-if="form.auditStatus==0" style="float: right; padding: 3px 0;width:150px;color:blue;">待审核</span>
          <span v-else style="float: right; padding: 3px 0;width:150px;color:red;">审核未通过</span>
        </div>
        <el-form-item label="企业名称：">{{form.comname}}</el-form-item>
        <br />
        <el-form-item label="企业简称：">
          <el-input v-model="form.simpleName"></el-input>
        </el-form-item>
        <el-form-item label="企业性质：">
          <el-input v-model="form.nature"></el-input>
        </el-form-item>
        <el-form-item label="所属行业：">
          <el-input v-model="form.tradeId"></el-input>
        </el-form-item>
        <el-form-item label="注册资金：">
          <el-input-number
            v-model="form.regMoney"
            :precision="2"
            :step="10000"
            :min="0"
            style="width:185px;"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="企业固话：">
          <el-input v-model="form.comPhone"></el-input>
        </el-form-item>
        <el-form-item label="注册地：">
          <el-select v-model="form.regAddress" filterable style="width:185px;">
            <el-option
              v-for="item in regionList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经营范围：">
          <el-input type="textarea" :rows="2" v-model="form.description" style="width:185px;"></el-input>
        </el-form-item>
        <el-form-item label="特种经营许可：">
          <div class="flex">
            <el-upload
              class="avatar-uploader"
              :action="url"
              :on-preview="handlePreview"
              :before-remove="beforeRemove"
              multiple
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :http-request="httpRequest"
            >
              <img v-if="form.espLicense" :src="form.espLicense" class="avatar" />
              <i v-else class="avatar el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="营业执照：">
          <div class="flex">
            <el-upload
              class="avatar-uploader"
              :action="url"
              :on-preview="handlePreview1"
              :before-remove="beforeRemove1"
              multiple
              :limit="1"
              :on-exceed="handleExceed1"
              :file-list="fileList1"
              :http-request="httpRequest1"
            >
              <img v-if="form.organizationUrl" :src="form.organizationUrl" class="avatar" />
              <i v-else class="avatar el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="开户许可：">
          <div class="flex">
            <el-upload
              class="avatar-uploader"
              :action="url"
              :on-preview="handlePreview2"
              :before-remove="beforeRemove2"
              multiple
              :limit="1"
              :on-exceed="handleExceed2"
              :file-list="fileList2"
              :http-request="httpRequest2"
            >
              <img v-if="form.openLicense" :src="form.openLicense" class="avatar" />
              <i v-else class="avatar el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
      </el-card>
      <el-card shadow="never" class="company-card">
        <div slot="header" class="clearfix">
          <span>法人信息</span>
        </div>
        <el-form-item label="法人姓名：">
          <el-input v-model="form.legalName"></el-input>
        </el-form-item>
        <el-form-item label="身份证号：">
          <el-input v-model="form.legalIdCard"></el-input>
        </el-form-item>
        <el-form-item label="法人电话：">
          <el-input v-model="form.legalMobile"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="身份证正面：">
          <div class="flex">
            <el-upload
              class="avatar-uploader"
              :action="url"
              :on-preview="handlePreview3"
              :before-remove="beforeRemove3"
              multiple
              :limit="1"
              :on-exceed="handleExceed3"
              :file-list="fileList3"
              :http-request="httpRequest3"
            >
              <img v-if="form.legalId1Url" :src="form.legalId1Url" class="avatar" />
              <i v-else class="avatar el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="身份证反面：">
          <div class="flex">
            <el-upload
              class="avatar-uploader"
              :action="url"
              :on-preview="handlePreview3"
              :before-remove="beforeRemove3"
              multiple
              :limit="1"
              :on-exceed="handleExceed3"
              :file-list="fileList3"
              :http-request="httpRequest3"
            >
              <img v-if="form.legalId2Url" :src="form.legalId2Url" class="avatar" />
              <i v-else class="avatar el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
      </el-card>
      <el-card shadow="never" class="company-card">
        <div slot="header" class="clearfix">
          <span>授权委托人信息</span>
        </div>
        <el-form-item label="授权委托人姓名：">
          <el-input v-model="form.authName"></el-input>
        </el-form-item>
        <el-form-item label="授权委托人电话：">
          <el-input v-model="form.authMobile"></el-input>
        </el-form-item>
        <el-form-item label="身份证号：">
          <el-input v-model="form.authIdCard"></el-input>
        </el-form-item>
        <el-form-item label="授权委托书：">
          <div class="flex">
            <el-upload
              class="upload-demo1"
              :action="url"
              :on-preview="handlePreview7"
              :before-remove="beforeRemove7"
              multiple
              :limit="1"
              :on-exceed="handleExceed7"
              :file-list="fileList7"
              :http-request="httpRequest7"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button v-if="form.authFile" size="small" type="text" style="margin-left:20px;" @click="downloadFile">下载</el-button>
          </div>
        </el-form-item>
        <el-form-item label="身份证正面：">
          <div class="flex">
            <el-upload
              class="avatar-uploader"
              :action="url"
              :on-preview="handlePreview5"
              :before-remove="beforeRemove5"
              multiple
              :limit="1"
              :on-exceed="handleExceed5"
              :file-list="fileList5"
              :http-request="httpRequest5"
            >
              <img v-if="form.authId1Url" :src="form.authId1Url" class="avatar" />
              <i v-else class="avatar el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="身份证反面：">
          <div class="flex">
            <el-upload
              class="avatar-uploader"
              :action="url"
              :on-preview="handlePreview6"
              :before-remove="beforeRemove6"
              multiple
              :limit="1"
              :on-exceed="handleExceed6"
              :file-list="fileList6"
              :http-request="httpRequest6"
            >
              <img v-if="form.authId2Url" :src="form.authId2Url" class="avatar" />
              <i v-else class="avatar el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
      </el-card>
      <br />
      <el-form-item>
        <el-button type="primary" @click="handleUpdate()">修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        comid: 0,
        comname: "",
        organizationCode: "",
        organizationUrl: "",
        legalName: "",
        legalMobile: "",
        legalId1Url: "",
        legalId2Url: "",
        contactName: "",
        contactMobile: "",
        contactEmail: "",
        tradeId: "",
        regMoney: "",
        logo: "",
        description: "",
        simpleName: "",
        nature: "",
        comPhone: "",
        regAddress: "",
        legalIdCard: "",
        authName: "",
        authMobile: "",
        authIdCard: "",
        authId1Url: "",
        authId2Url: "",
        espLicense: "",
        openLicense: "",
        authFile: "",
      },
      regionList: [],
      url: "",
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      fileList6: [],
      fileList7: [],
      auditStatus: 0
    };
  },
  created() {
    this.auditStatus = this.$store.state.user.auditStatus;
    this.getComData();
    this.getRegion();
  },
  methods: {
    getComData() {
      this.$get("/pm/ucenter/getCompanyInfo").then(res => {
        this.form = res.data;
      });
    },
    getRegion() {
      this.$get("/pm/ucenter/getRegion").then(res => {
        this.regionList = res.data;
      });
    },
    handleUpdate() {
      this.$post("/pm/ucenter/company", this.form).then(res => {
        this.$message({
          message: "修改成功！",
          type: "success"
        });
        this.getComData();
      });
    },
    downloadFile(){
      window.open(this.form.authFile);
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest(params) {
      let fileForm = new FormData();
      fileForm.append("file", params.file);
      this.$post("/pm/ucenter/upload", fileForm).then(res => {
        this.form.espLicense = res.url;
      });
    },
    handleRemove1(file, fileList1) {},
    handlePreview1(file) {},
    handleExceed1(files, fileList1) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList1.length} 个文件`
      );
    },
    beforeRemove1(file, fileList1) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest1(params) {
      let fileForm = new FormData();
      fileForm.append("file", params.file);
      this.$post("/pm/ucenter/upload", fileForm).then(res => {
        this.form.organizationUrl = res.url;
      });
    },
    handleRemove2(file, fileList2) {},
    handlePreview2(file) {},
    handleExceed2(files, fileList2) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList2.length} 个文件`
      );
    },
    beforeRemove2(file, fileList2) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest2(params) {
      let fileForm = new FormData();
      fileForm.append("file", params.file);
      this.$post("/pm/ucenter/upload", fileForm).then(res => {
        this.form.openLicense = res.url;
      });
    },
    handleRemove3(file, fileList3) {},
    handlePreview3(file) {},
    handleExceed3(files, fileList3) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList3.length} 个文件`
      );
    },
    beforeRemove3(file, fileList3) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest3(params) {
      let fileForm = new FormData();
      fileForm.append("file", params.file);
      this.$post("/pm/ucenter/upload", fileForm).then(res => {
        this.form.legalId1Url = res.url;
      });
    },
    handleRemove4(file, fileList4) {},
    handlePreview4(file) {},
    handleExceed4(files, fileList4) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList4.length} 个文件`
      );
    },
    beforeRemove4(file, fileList4) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest4(params) {
      let fileForm = new FormData();
      fileForm.append("file", params.file);
      this.$post("/pm/ucenter/upload", fileForm).then(res => {
        this.form.legalId2Url = res.url;
      });
    },
    handleRemove5(file, fileList5) {},
    handlePreview5(file) {},
    handleExceed5(files, fileList5) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList5.length} 个文件`
      );
    },
    beforeRemove5(file, fileList5) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest5(params) {
      let fileForm = new FormData();
      fileForm.append("file", params.file);
      this.$post("/pm/ucenter/upload", fileForm).then(res => {
        this.form.authId1Url = res.url;
      });
    },
    handleRemove6(file, fileList6) {},
    handlePreview6(file) {},
    handleExceed6(files, fileList6) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList6.length} 个文件`
      );
    },
    beforeRemove6(file, fileList6) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest6(params) {
      let fileForm = new FormData();
      fileForm.append("file", params.file);
      this.$post("/pm/ucenter/upload", fileForm).then(res => {
        this.form.authId2Url = res.url;
      });
    },
    handleRemove7(file, fileList7) {},
    handlePreview7(file) {},
    handleExceed7(files, fileList7) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList7.length} 个文件`
      );
    },
    beforeRemove7(file, fileList7) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest7(params) {
      let fileForm = new FormData();
      fileForm.append("file", params.file);
      this.$post("/pm/ucenter/upload", fileForm).then(res => {
        this.form.authFile = res.url;
      });
    }
  }
};
</script>

<style>
.company-card {
  width: 900px;
  margin: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 160px;
  height: 200px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 185px;
  height: 120px;
  display: block;
}
</style>