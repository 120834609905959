<template>
  <div>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="未读" name="0"></el-tab-pane>
        <el-tab-pane label="已读" name="1"></el-tab-pane>
      </el-tabs>
      <el-table
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="createTime" label="日期" width="200">
          <template slot-scope="scope">{{scope.row.createTime | dateTimeFormat}}</template>
        </el-table-column>
        <el-table-column prop="title" label="标题" width="200"></el-table-column>
        <el-table-column prop="decribe" label="描述"></el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-view"
              @click="handleUpdate(scope.row)"
            >查看</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pages container mx-auto text-center mt-4 my-2">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="queryParams.pageSize"
        :total="total"
        @current-change="pageChange"
      ></el-pagination>
    </div>
    <el-dialog title="消息详情" :visible.sync="open" width="700px" append-to-body>
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="标题：" prop="title">{{form.title}}</el-form-item>
        <el-form-item label="描述：" prop="decribe">{{form.decribe}}</el-form-item>
        <el-form-item label="内容：">
          <span v-html="form.content"></span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "0",
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        status: "0"
      },
      tableData: [],
      open: false,
      messageForm: {
        id: undefined,
        status: undefined
      },
      form: {
        id: undefined,
        projectId: undefined,
        goodsId: undefined,
        type: undefined,
        channel: undefined,
        title: undefined,
        decribe: undefined,
        content: undefined,
        status: 0,
        reversion: undefined,
        createBy: undefined,
        createTime: undefined,
        updateBy: undefined,
        updateTime: undefined,
        projectName: undefined,
        goodsName: undefined,
        agentId: undefined
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableData = [];
      this.$get("/pm/ucenter/listMessages", this.queryParams).then(res => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    handleClick() {
      this.queryParams.status = this.activeName;
      this.getList();
    },
    pageChange(n) {
      this.queryParams.pageNum = n;
      this.getList();
    },
    handleUpdate(row) {
      this.form.title = row.title;
      this.form.decribe = row.decribe;
      this.form.content = row.content;
      this.form.status = row.status;
      this.open = true;
      if (this.form.status == 0) {
        //未读
        this.messageForm.id = row.id;
        this.messageForm.status = 1;
        this.$get("/pm/ucenter/message", this.messageForm).then(res => {
          console.log(res);
        });
      }
    },
    handleDelete(row) {
      this.messageForm.id = row.id;
      this.messageForm.status = -1;
      var _this = this;
      this.$confirm("是否删除该消息?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(function() {
          _this.$get("/pm/ucenter/message", _this.messageForm).then(res => {
            _this.$message({
                message: "删除成功！",
                type: "success"
              });
              _this.getList();
          });
        })
        .then(() => {});
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.messageForm.id = undefined;
      this.messageForm.status = undefined;
      this.getList();
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status === 0) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    handleCurrentChange(val) {
      this.form.title = val.title;
      this.form.decribe = val.decribe;
      this.form.content = val.content;
      this.form.status = val.status;
      this.open = true;
      if (this.form.status == 0) {
        //未读
        this.messageForm.id = val.id;
        this.messageForm.status = 1;
        this.$get("/pm/ucenter/message", this.messageForm).then(res => {
          console.log(res);
        });
      }
    }
  }
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>